import React from "react";
import styled from "styled-components";
import { device } from "../../assets/breakbpoints";
import { theme } from "../../assets/theme";
import { fontFamily } from "../../assets/fontFamily";

const StyledButton = styled.button<PrimaryButtonComponentProps>`
  border-radius: 10px;
  padding: 13px 14px;
  background-color: ${(props) =>
    props.inActive ? theme.mutedColor : theme.primaryColor};
  color: ${theme.white};
  font-family: ${fontFamily.manrope};
  font-weight: bold;
  border: none;
  font-size: 16px;
  cursor: pointer;
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.08);
  width: 100%;

  &:hover,
  &:focus {
    background-color: ${(props) =>
      props.inActive ? theme.mutedColor : theme.primaryColor};
    color: ${theme.black};
    outline: none;
  }

  &:hover {
    box-shadow: none;
  }

  @media ${device.laptop} {
    width: 50%;
    font-size: 1.2vw;
  }

  @media ${device.desktopL} {
    padding: 20px 17px;
  }
`;

const ApptStyledButton = styled.button<PrimaryButtonComponentProps>`
  border-radius: 10px;
  padding: 13px 14px;
  background-color: ${(props) =>
    props.inActive ? theme.mutedColor : theme.primaryColor};
  color: ${theme.black};
  font-family: ${fontFamily.manrope};
  font-weight: bold;
  border: none;
  font-size: 13px;
  cursor: pointer;
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.08);
  width: 20% !important;
  display: block;
  margin: 0 auto;

  &:hover,
  &:focus {
    background-color: ${(props) =>
      props.inActive ? theme.mutedColor : theme.primaryColor};
    color: ${theme.black};
    outline: none;
  }

  &:hover {
    box-shadow: none;
  }

  @media ${device.laptop} {
    width: 50%;
    font-size: 1.2vw;
  }

  @media ${device.desktopL} {
    padding: 20px 17px;
  }
`;

interface PrimaryButtonComponentProps
  extends React.ComponentPropsWithoutRef<"button"> {
  inActive?: boolean;
  width?: string;
  buttonText?: string;
  onKeyDown?: any;
  height?: string;
  fontSize?: string;
  leftMargin?: string;
}

export const PrimaryButtonComponent = (props: PrimaryButtonComponentProps) => (
  <StyledButton
    style={{
      width: props.width,
      height: props.height,
      fontSize: props.fontSize,
      marginLeft: props.leftMargin,
    }}
    inActive={props.inActive}
    onKeyDown={props.onDoubleClick}
    onMouseDown={props.onMouseDown}
    onClick={props.onClick}
  >
    {props.buttonText}
  </StyledButton>
);

export const ApptButtonComponent = (props: PrimaryButtonComponentProps) => (
  <ApptStyledButton
    style={{ width: props.width }}
    inActive={props.inActive}
    onKeyDown={props.onDoubleClick}
    onMouseDown={props.onMouseDown}
    onClick={props.onClick}
  >
    {props.buttonText}
  </ApptStyledButton>
);
