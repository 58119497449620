export const theme = {
  primaryColor: "#254EA1",
  black: "#2D3743",
  white: "#ffffff",
  secondaryColor: "#2D353E",
  secondaryColor_darker: "#2D353E",
  secondaryColor_lighter: "#2D353E",
  secondaryColor_asset: "#2D353E",
  grey: "#A9AEB5",
  normalText: "#414457",
  mutedColor: "#A9AEB5",
  red: "#EB5757",
};
