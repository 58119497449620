import React, { useState } from "react";
import { validate } from "email-validator";
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { Alert } from "antd";
import {
  authenticate,
  firebaseAuth,
  getUserPermissionFunction,
} from "../../store/firebase";
import sideImage1 from "../../assets/images/sideImage1.png";
import { InputComponent } from "../elements/InputComponent";
import { TextInput } from "../elements/TextInput";
import { Loader } from "../elements/Loader";
import { PrimaryButtonComponent } from "../elements/PrimaryButtonComponent";
import {
  StyledAlert,
  StyledCenteredDiv,
  StyledDivider,
  StyledEventButton,
} from "../elements/UIElements";
import { Validation } from "../elements/Validation";
import { OnBoardingTopText } from "../elements/OnBoardingTopText";
import { OnBoardingScaffold } from "../layout/OnBoardingScaffold";
import { getUserUniqueId, generateMedconHash } from "../../utils/hash";
import {
  initializePendo,
  initializeFullStory,
} from "../../utils/web-analytics";

declare const document: any;

export const WelcomeScreen = () => {
  const minPasswordLength = 8;
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailValidation, showEmailValidation] = useState(false);
  const [passwordValidation, showPasswordValidation] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [loader, showLoader] = useState(false);

  // const onPatientClick = () => {
  //   history.push(`/patient/signin`);
  //  };

  const alertClose = () => {
    setAlertVisible(false);
  };

  const clearValidation = () => {
    showEmailValidation(false);
    showPasswordValidation(false);
    showLoader(true);
  };

  const proceedWithSignIn = (signInEmail: string) => {
    // get the permission for user
    const getUserPermission = getUserPermissionFunction();
    getUserPermission({ email: signInEmail }).then((response: any) => {
      const userPermission = response.data;
      if (userPermission && !userPermission.locked && userPermission.role) {
        const uniqueId = getUserUniqueId();
        generateMedconHash(signInEmail).then((visitorId) => {
          // initialize pendo
          initializePendo({
            accountUniqueId: uniqueId,
            visitorUniqueId: visitorId,
          });

          // intialize full story
          initializeFullStory({
            visitorUniqueId: visitorId,
          });
        });
        history.push(`/provider/overview`, {
          permission: userPermission,
        });
      } else {
        setAlertVisible(true);
        showLoader(false);
      }
    });
  };

  const signInWithEmail = () => {
    setAlertVisible(false);
    showLoader(true);
    firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then((userCredential: any) => {
        proceedWithSignIn(userCredential.user.email);
      })
      .catch(() => {
        setAlertVisible(true);
        showLoader(false);
      });
  };

  const signInWithGoogle = () => {
    setAlertVisible(false);
    showLoader(true);
    authenticate()
      .then((userCredential: any) => {
        proceedWithSignIn(userCredential.user.email);
      })
      .catch(() => {
        setAlertVisible(true);
        showLoader(false);
      });
  };

  const signInWithApple = () => {
    // do apple sign in
  };
  const handleClick = async () => {
    clearValidation();

    if (!email || !validate(email.trim())) {
      showEmailValidation(true);
      showLoader(false);
      return;
    }

    if (!password || password.length < minPasswordLength) {
      showPasswordValidation(true);
      showLoader(false);
      return;
    }
    signInWithEmail();
  };

  return (
    <OnBoardingScaffold
      topItem={
        <OnBoardingTopText
          prefixText="Log In to medcon"
          logo=""
          segment=""
          botMessage=""
        />
      }
      sideImage={sideImage1}
    >
      {alertVisible ? (
        <StyledAlert>
          <Alert
            message="We are unable to sign you in, please try again"
            type="error"
            showIcon
            closable
            onClose={alertClose}
          />
        </StyledAlert>
      ) : (
        ""
      )}
      <form
        onSubmit={async (e: any) => {
          e.preventDefault();
        }}
      >
        <InputComponent
          input={
            <TextInput
              onChange={(e: any) => {
                setEmail(e.target.value);
              }}
              type="text"
              placeholder="Email"
              onKeyUp={(e: any) => {
                if (e.keyCode === 13) {
                  document.getElementById("logInPassword").focus();
                }
              }}
            />
          }
          validation={
            <Validation
              text="Please enter valid email address"
              visible={emailValidation}
            />
          }
        />
        <InputComponent
          input={
            <TextInput
              type="password"
              placeholder="Password"
              onChange={(e: any) => {
                setPassword(e.target.value);
              }}
              maxLength={50}
              onKeyPress={(e: any) => {
                if (e && e.length < minPasswordLength) {
                  e.preventDefault();
                  return false;
                }
                return true;
              }}
              onKeyUp={(e: any) => {
                if (e.keyCode === 13) {
                  handleClick();
                }
              }}
              id="logInPassword"
            />
          }
          validation={
            <Validation
              text="Password must be at least eight(8) or more characters"
              visible={passwordValidation}
            />
          }
        />
        <PrimaryButtonComponent onClick={handleClick} buttonText="Sign In" />
      </form>
      <StyledDivider>or</StyledDivider>

      <StyledEventButton
        width={50}
        onClick={() => {
          signInWithGoogle();
        }}
      >
        <FcGoogle size={15} />
        <p>Sign in with Google</p>
      </StyledEventButton>
      <br />

      <StyledEventButton
        width={50}
        onClick={() => {
          signInWithApple();
        }}
      >
        <FaApple size={15} />
        <p>Sign in with Apple</p>
      </StyledEventButton>

      <StyledCenteredDiv>
        <br />
        <br />
        <a href="">Forgot Your Password?</a>
        <br />
        <br />
        <p>
          Dont have a Medcon account yet?
          <a href="/register"> Sign up now.</a>
        </p>
      </StyledCenteredDiv>

      <Loader visible={loader} text="Please wait ... " />
    </OnBoardingScaffold>
  );
};
