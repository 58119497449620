import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../assets/breakbpoints";
import { fontFamily } from "../../assets/fontFamily";
import sideImage6 from "../../assets/images/sideImage6.png";
import { BackButton } from "../elements/BackButton";
import { PrimaryButtonComponent } from "../elements/PrimaryButtonComponent";
import { OnBoardingScaffold } from "../layout/OnBoardingScaffold";

const StyledTopText = styled.p`
  font-size: 36px;
  font-family: ${fontFamily.heading};
  font-weight: 500;
  color: #101625;
  margin-bottom: 30px;

  & span {
    color: #336cff;
  }

  @media ${device.laptop} {
    font-size: 2.4vw;
  }
`;
const StyledFooterText = styled.p`
  width: 100%;
  margin-bottom: 30px;
  font-size: 18px;
  font-family: ${fontFamily.body};
  font-weight: 400;

  @media ${device.tablet} {
    width: 70%;
  }

  @media ${device.laptop} {
    width: 60%;
    font-size: 1.4vw;
  }
`;

const StyledContactText = styled.p`
  width: 100%;
  margin-bottom: 30px;
  font-size: 14px;
  font-family: ${fontFamily.body};
  font-weight: 200;

  @media ${device.tablet} {
    width: 70%;
  }

  @media ${device.laptop} {
    width: 60%;
    font-size: 1vw;
  }
`;

export const CheckinFailed = () => {
  const browserLocation = useLocation();
  const history = useHistory();
  const [failedProps] = useState(
    browserLocation.state as { message: string; allowBack?: boolean }
  );

  if (!failedProps) {
    history.push("/");
  }

  return (
    <OnBoardingScaffold sideImage={sideImage6}>
      <StyledTopText>Oops!</StyledTopText>
      <StyledFooterText>{failedProps.message}</StyledFooterText>
      <StyledContactText>
        <b>Contact:</b> 212-828-7473 ext. 2
        <br />
        <b>Email:</b> billing@humantold.com
      </StyledContactText>
      <PrimaryButtonComponent
        buttonText="Contact us"
        onClick={() => {
          window.location.href = "https://humantold.com/book-an-appointment/";
        }}
      />
      {failedProps.allowBack && <BackButton />}
    </OnBoardingScaffold>
  );
};
