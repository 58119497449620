export enum TimeZone {
  EST = "EST",
  AKST = "AKST",
  PST = "PST",
  MST = "MST",
  CST = "CST",
  UTC = "UTC",
}

export enum TimeZoneLabel {
  EST = "EST - Eastern Standard Time",
  AKST = "AKST - Alaska Standard Time",
  PST = "PST - Pacific Standard Time",
  MST = "MST - Mountain Standard Time",
  CST = "CST - Central Standard Time",
  UTC = "UTC - Coordinated Universal Time",
}

export enum RecurrentRule {
  Weekly = "WEEKLY",
  BiWeekly = "BI-WEEKLY",
  Monthly = "MONTHLY",
}

export enum RequestedServiceType {
  IndividualTherapy = "INDIVIDUAL THERAPY",
  CouplesTherapy = "COUPLES THERAPY",
  ChildTherapy = "CHILD THERAPY",
  FamilyTherapy = "FAMILY THERAPY",
  TeleTherapy = "TELETHERAPY",
}

export enum Currency {
  USD = "USD",
  GBP = "GBP",
  EUR = "EUR",
  CAD = "CAD",
}

export enum CurrencyLabel {
  USD = "USD - US dollar",
  GBP = "GBP - Pound Sterling",
  EUR = "EUR - Euro",
  CAD = "CAD - Canadian Dollar",
}

export enum DateFormat {
  MMDDYYY = "mm/dd/yyyy",
  YYYYMMDD = "yyyy-mm-dd",
  DDMMYYYY = "dd/mm/yyyy",
}

export enum Location {
  Madison = "MADISON",
  UpperEastSide = "UPPER EAST SIDE",
  Brooklyn = "BROOKLYN",
  FinancialDistrict = "FINANCIAL DISTRICT",
  Remote = "REMOTE",
}

// Notification alerts enum

export enum NotificationType {
  Success = "success",
  Warning = "warning",
  Error = "error",
  Info = "info",
}

export enum NotificationPosition {
  Top = "top",
  TopLeft = "top-left",
  TopRight = "top-right",
  Bottom = "bottom",
  BottomLeft = "bottom-left",
  BottomRight = "bottom-right",
}

// Payment related enums

export enum PaymentCaptureType {
  Authorize = "authorize",
  Capture = "capture",
  AuthorizeAndCapture = "authorizeandcapture",
  NoPayment = "no-payment",
}

export enum PaymentStatus {
  Hold = "hold",
  Failed = "failed",
  Success = "success",
  Completed = "completed",
  Refunded = "refunded",
  Voided = "voided",
}

export enum PaymentProvider {
  AuthorizeNet = "authorize.net",
}

export enum Role {
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  CLIENT = "CLIENT",
  BILLER = "BILLER",
  MANAGER = "MANAGER",
  PROVIDER = "PROVIDER",
}

export enum AppointmentType {
  WalkIn = "walk_in",
  TeleConsult = "teleconsult",
}

export enum VisibilityStatus {
  Active = 0,
  Archived = 1,
  Deleted = 2,
}

export enum MeetingStatus {
  STARTED = "STARTED",
  ENDED = "ENDED",
}

export enum MeetingType {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}
