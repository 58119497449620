import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { FiEdit } from "react-icons/fi";
import { useScreenNameContext } from "../context/screenNameContext";
import { PermissionContext } from "../context/permissionContext";
import { TableMini } from "./atoms/TableMini";
import { Loader } from "../elements/Loader";
import { Role } from "../../types/enum";
import { getBackendUsersFunction } from "../../store/firebase";

declare const window: any;

const title = ["name", "email", "first login date", "last access date", ""];

export const Permissions = () => {
  const history = useHistory();
  const [loader, showLoader] = useState(true);
  const { permission } = useContext(PermissionContext) as any;
  const [usersData, setUsersData] = useState([] as any);
  const maxDisplayUsers = 1000;
  if (!permission || permission.role === Role.PROVIDER) {
    history.push("/provider/overview", { permission });
  }

  const { setCurrentPageName } = useScreenNameContext() as any;

  window.navigateToUser = (user: any) => {
    const link = `/provider/permissions/update/?email=${user.email}&fullName=${
      user.displayName ? user.displayName : ""
    }`;
    history.push(link);
  };

  useEffect(() => {
    setCurrentPageName("Access Control & Permissions");
    const getUsers = getBackendUsersFunction();
    getUsers({ totalRecords: maxDisplayUsers }).then((response: any) => {
      const data: any[] = [];
      Object.values(response.data).forEach((user: any) => {
        const row = {
          text: user.displayName || user.email,
          field1: user.email,
          field2: moment(user.metadata.creationTime).format(
            "MMMM, DD yyyy HH:mm"
          ),
          field3: user.metadata.lastSignInTime
            ? moment(user.metadata.lastSignInTime).format("MMMM, DD yyyy HH:mm")
            : "",
          actions: [
            {
              text: "Update",
              handler: window.navigateToUser,
              param: user,
              type: "button",
              icon: <FiEdit />,
            },
          ],
        };
        data.push(row);
      });

      setUsersData(data);
      showLoader(false);
    });
  }, []);

  return (
    <>
      <TableMini
        sessionName="Authorized users information"
        tableTitle={title}
        tableName="All Users"
        showLocationFilter={false}
        showDateFilter={false}
        data={usersData}
        isActive
        showSearch
      />
      <Loader visible={loader} text="Loading ... " />
    </>
  );
};
