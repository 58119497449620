import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { Alert, Col } from "antd";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { FiCopy } from "react-icons/fi";
import { FcCalendar, FcDeleteRow, FcVideoCall } from "react-icons/fc";
import { useScreenNameContext } from "../context/screenNameContext";
import { PermissionContext } from "../context/permissionContext";
import { TableMini } from "./atoms/TableMini";
import { Loader } from "../elements/Loader";
import { StyledButton } from "./atoms/StyledButton";
import { Meeting } from "../../types/meeting";
import { MeetingStatus } from "../../types/enum";
import MeetingStore from "../../store/meeting";
import { ProviderPath } from "../../types/provider";

declare const window: any;

const title = [
  "Topic",
  "Description",
  "Date & Time",
  "Meeeting Type & Invitees",
  "",
];
const csvHeaders = [
  { label: "Topic", key: "text" },
  { label: "Description", key: "field1" },
  { label: "Date & Time", key: "field2" },
  { label: "Invitees", key: "field3" },
];

export const Meetings = () => {
  const history = useHistory();
  const [loader, showLoader] = useState(true);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [meetingsData, setMeetingsData] = useState([] as any);
  const { permission } = useContext(PermissionContext) as any;
  const { setCurrentPageName } = useScreenNameContext() as any;

  window.copyMeetingLink = (link: string) => {
    setAlertMessage("Meeting link has been copied");
    const textarea = document.createElement("textarea");
    document.body.appendChild(textarea);
    textarea.value = link;
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setAlertVisible(true);
  };

  window.joinMeeting = (meeting: Meeting) => {
    history.push(ProviderPath.GROUP_MEETING, {
      meetingDetails: {
        meeting,
        participant: {
          name: "Host",
          id: permission.id,
        },
      },
    });
  };

  const parseInvitees = (meeting: any) => {
    let parsedInvitees: string;
    if (meeting.invitees) {
      parsedInvitees = `${meeting.meetingType} | `;
      Object.values(meeting.invitees).forEach((invitee: any) => {
        parsedInvitees = `${parsedInvitees} ${invitee.label},`;
      });
      parsedInvitees = parsedInvitees.slice(0, -1);
    } else {
      parsedInvitees = `${meeting.meetingType}`;
    }
    return parsedInvitees;
  };

  const loadMeetings = () => {
    if (permission) {
      const meetingStore = new MeetingStore();
      meetingStore.getAllMeetings().then((meetings: Meeting[]) => {
        const formattedPatients = [] as any[];
        Object.values(meetings).forEach((meeting: Meeting) => {
          if (
            meeting.host === permission.id ||
            _.find(meeting.invitees, { value: permission.id })
          ) {
            const meetingStart = moment(meeting.startTime);
            const meetingEnd = moment(meeting.endTime);
            const actions = [];

            if (meeting.status !== MeetingStatus.ENDED) {
              actions.push(
                {
                  text: "Join",
                  handler: window.joinMeeting,
                  param: meeting,
                  type: "button",
                  icon: <FcVideoCall />,
                },
                {
                  text: "Copy",
                  handler: window.copyMeetingLink,
                  param: meeting.link,
                  type: "button",
                  icon: <FiCopy />,
                }
              );
            }
            actions.push({
              text: "Delete",
              handler: window.deleteMeeting,
              param: meeting.id,
              type: "button",
              icon: <FcDeleteRow />,
            });

            formattedPatients.push({
              text: meeting.topic,
              field1: meeting.description,
              field2: `${meetingStart.format(
                "MMMM D yyyy"
              )} | ${meetingStart.format("HH:mm")} - ${meetingEnd.format(
                "HH:mm"
              )} ${meeting.timeZone}`,
              field3: parseInvitees(meeting),
              actions,
              date: meetingStart,
            });
          }
        });
        setMeetingsData(formattedPatients);
        showLoader(false);
      });
    }
  };

  window.deleteMeeting = (meetingId: string) => {
    confirmAlert({
      title: "Confirm action",
      message: "Are you sure you want to delete the scheduled meeting?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            showLoader(true);
            const meetingStore = new MeetingStore();
            meetingStore.deleteMeeting(meetingId).then(() => {
              setAlertMessage("Scheduled meeting has been deleted");
              setAlertVisible(true);
              loadMeetings();
            });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    setCurrentPageName("Meetings");
    loadMeetings();
  }, [permission]);

  return (
    <>
      <Col xs={{ span: 24 }} style={{ marginTop: "30px" }}>
        {alertVisible ? (
          <>
            <Alert
              message={alertMessage}
              type="info"
              showIcon
              closable
              onClose={() => {
                setAlertVisible(false);
              }}
            />
            <br />
          </>
        ) : (
          ""
        )}

        <StyledButton
          icon={<FcCalendar />}
          text="Schedule Meeting"
          onClick={() => {
            history.push("/provider/meetings/create-meeting");
          }}
        />

        <TableMini
          sessionName=""
          tableTitle={title}
          tableName="All Meetings"
          showLocationFilter={false}
          showDateFilter={false}
          data={meetingsData}
          csvHeaders={csvHeaders}
          isActive
          showSearch
        />
      </Col>
      <Loader visible={loader} text="Loading ... " />
    </>
  );
};
