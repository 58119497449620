import { Col, DatePicker, Input, Select, TimePicker, Form, Alert } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import styled from "styled-components";
import { Loader } from "../../elements/Loader";
import { device } from "../../../assets/breakbpoints";
import { fontFamily } from "../../../assets/fontFamily";
import PatientStore from "../../../store/patient";
import ProviderStore from "../../../store/provider";
import {
  RequestedServiceType,
  Location,
  Currency,
  TimeZone,
} from "../../../types/enum";
import { Patient } from "../../../types/patient";
import { Provider } from "../../../types/provider";
import { getUniqueId } from "../../../utils/hash";
import { useScreenNameContext } from "../../context/screenNameContext";
import { ApptButtonComponent } from "../../elements/PrimaryButtonComponent";
import { BackButton } from "../atoms/BackButton";
import { ApptValue } from "../../../types/appt-value";

const StyledCreateAppointmentForm = styled.div`
  background-color: #fff;
  border-radius: 16px;
  padding: 51px;
  margin-top: 30px;

  & h1 {
    color: #131523;
    font-size: 16px;
    font-family: ${fontFamily.Inter};
    font-weight: 700;
  }

  & > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  @media ${device.laptop} {
    & h1 {
      font-size: 1.2vw;
    }
  }
`;

export const CreateAppointmentForm = () => {
  const { setCurrentPageName } = useScreenNameContext() as any;
  setCurrentPageName("Appointment");
  const patientStore = new PatientStore();
  const providerStore = new ProviderStore();
  const [loader, showLoader] = useState(true);
  const browserLocation = useLocation();
  const [provider] = useState(
    (browserLocation.state as any)?.provider as Provider
  );
  const parsedQueryParams = queryString.parse(browserLocation.search || "");
  const [alertVisible, setAlertVisible] = useState(false);
  const [actionPerformed, setActionPerfomed] = useState("");
  const [extApptId] = useState(parsedQueryParams.appointmentId as string);
  const [extProviderId] = useState(parsedQueryParams.providerId as string);
  const [extPatientId] = useState(parsedQueryParams.patientId as string);
  const [appt, setAppt] = useState({} as ApptValue);
  const [patients, setPatients] = useState<Patient[]>([]);
  const [patientId, setPatientId] = useState<string>("Select Client...");
  const [apptDate, setApptDate] = useState(moment());
  const [copay, setCopay] = useState("");
  const [location, setLocation] = useState("Select Location...");
  const [recurrentRule, setRecurrentRule] = useState("");
  const [requestType, setRequestType] = useState("Select Service...");
  const [time, setTime] = useState(moment());
  const [timeZone, setTimeZone] = useState("Select Timezone...");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  useEffect(() => {
    if (extApptId && extProviderId && extPatientId) {
      setCurrentPageName("Update Appointment");
      providerStore
        .getProviderAppointment(extProviderId, extPatientId, extApptId)
        .then((data: ApptValue) => {
          if (data) {
            setApptDate(moment(data.appointmentDate));
            setCopay(data.copayAmount!.toString());
            setLocation(data.location);
            setRecurrentRule(data.recurrentRule);
            setRequestType(data.requestedService);
            setTime(moment(data.time));
            setTimeZone(data.timeZone!);
            setFirstName(data.clientFirstName!);
            setLastName(data.clientLastName!);

            showLoader(false);
            setAppt(data);
            setActionPerfomed("updated");
          }
        });
    } else {
      setCurrentPageName("Create Appointment");
      patientStore.getAllPatient().then((data: Patient[]) => {
        setPatients(data);
        setActionPerfomed("created");
        showLoader(false);
      });
    }
  }, [extApptId, extProviderId, extPatientId]);

  function SaveAppointment() {
    let patientData = {} as Patient;
    let uniqueApptId = "";
    let providerId = "";

    if (extApptId) {
      patientData.email = appt?.clientEmail!;
      patientData.firstName = appt?.clientFirstName!;
      patientData.lastName = appt?.clientLastName!;
      patientData.contactNumber = appt?.clientPhoneNo!;
      patientData.preferredName = appt?.clientPreferedName!;
      patientData.id = appt?.patientId!;
      providerId = extProviderId;
      uniqueApptId = extApptId;
    } else {
      Object.values(patients).forEach((patient: Patient) => {
        if (patient.id === patientId) {
          patientData = patient;
        }
      });
      providerId = provider.id;
      uniqueApptId = getUniqueId();
    }
    const dbDateFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";
    const appointmentTime = time.format("HH:mm");
    const appointmentDate = apptDate.format("YYYY-MM-DD");
    const updatedAppointmentDate = moment(
      `${appointmentDate}T${appointmentTime}`
    );
    const endDateTime = moment(`${appointmentDate}T${appointmentTime}`).add(
      1,
      "hours"
    );
    const newAppt = {
      appointmentDate: updatedAppointmentDate.format(dbDateFormat),
      appointmentDateTs: updatedAppointmentDate.valueOf(),
      appointmentEndDate: endDateTime.format(dbDateFormat),
      appointmentEndDateTs: endDateTime.valueOf(),
      clientEmail: patientData.email,
      clientFirstName: patientData.firstName,
      clientLastName: patientData.lastName,
      clientPhoneNo: patientData.contactNumber,
      clientPreferedName: patientData.preferredName,
      copayAmount: parseFloat(copay),
      currency: Currency.USD.toString(),
      id: uniqueApptId,
      location,
      patientId: patientData.id,
      recurrentRule,
      requestedService: requestType.toString(),
      startingDate: updatedAppointmentDate.format(dbDateFormat),
      startingDateTs: updatedAppointmentDate.valueOf(),
      time: time.format(dbDateFormat),
      timeZone,
    };
    providerStore
      .patchAppointment(newAppt, providerId, patientData.id, uniqueApptId)
      .then((response: boolean) => {
        setAlertVisible(response);
      });
  }

  return (
    <>
      <Col xs={{ span: 24 }} lg={{ span: 20 }}>
        <BackButton />
        {alertVisible ? (
          <>
            <br />
            <Alert
              message={`Appointment ${actionPerformed} successfully, navigate to the Schedule page and refresh to see the updated appointment.`}
              type="success"
              showIcon
              closable
              onClose={() => {
                setAlertVisible(false);
              }}
            />
          </>
        ) : (
          ""
        )}

        <StyledCreateAppointmentForm>
          <h1>Appointment Information</h1>
          <br />

          <Form labelCol={{ span: 9 }} wrapperCol={{ span: 15 }}>
            {extApptId ? (
              ""
            ) : (
              <Form.Item
                label="Clients"
                style={{ float: "left", width: "50%" }}
              >
                <Select
                  value={patientId}
                  onChange={(e: any) => setPatientId(e)}
                >
                  {Object.values(patients).map((patient: Patient) => (
                    <Select.Option key={patient.id} value={patient.id}>
                      {`${patient.firstName} ${patient.lastName} (${patient.contactNumber})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <div style={{ clear: "both" }} />
            {extApptId ? (
              <Form.Item label="Client" style={{ float: "left", width: "50%" }}>
                <Input
                  readOnly
                  type="text"
                  value={`${firstName} ${lastName}`}
                />
              </Form.Item>
            ) : (
              ""
            )}
            <Form.Item label="Service" style={{ float: "left", width: "50%" }}>
              <Select
                value={requestType}
                onChange={(e: any) => setRequestType(e)}
              >
                {Object.values(RequestedServiceType).map((value) => (
                  <Select.Option value={value}>{value}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <div style={{ clear: "both" }} />
            <Form.Item label="Date" style={{ float: "left", width: "50%" }}>
              <DatePicker
                value={apptDate}
                format="MM/DD/YYYY"
                placeholder="MM/DD/YYYY"
                onChange={(e: any) => setApptDate(e)}
              />
            </Form.Item>
            <Form.Item label="Time" style={{ float: "left", width: "50%" }}>
              <TimePicker
                value={time}
                showNow
                onChange={(e: any) => setTime(e)}
              />
            </Form.Item>
            <div style={{ clear: "both" }} />
            <Form.Item label="Location" style={{ float: "left", width: "50%" }}>
              <Select value={location} onChange={(e: any) => setLocation(e)}>
                {Object.values(Location).map((value) => (
                  <Select.Option value={value}>{value}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Time Zone"
              style={{ float: "left", width: "50%" }}
            >
              <Select value={timeZone} onChange={(e: any) => setTimeZone(e)}>
                {Object.values(TimeZone).map((value) => (
                  <Select.Option value={value}>{value}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
          <div style={{ textAlign: "center" }}>
            <ApptButtonComponent
              buttonText={extApptId ? "Update" : "Create"}
              onClick={SaveAppointment}
            />
          </div>
        </StyledCreateAppointmentForm>
      </Col>
      <Loader visible={loader} text="Saving ... " />
    </>
  );
};
