import {
  ScheduleOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Col } from "antd";
import React, { useContext, useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { TiArrowRight } from "react-icons/ti";
import styled from "styled-components";
import moment from "moment";
import { Loader } from "../../elements/Loader";
import { device } from "../../../assets/breakbpoints";
import { fontFamily } from "../../../assets/fontFamily";
import waitingIcon1 from "../../../assets/images/waitingIcon1.png";
import { theme } from "../../../assets/theme";
import { useScreenNameContext } from "../../context/screenNameContext";
import { TimeIcon } from "../atoms/TimeIcon";
import {
  QuickAction,
  StatSummarySegment,
  TitledCard,
  EmptyWaitingList,
  EmptyUpcomingEvents,
} from "./shared";
import ProviderStore from "../../../store/provider";
import SessionStore from "../../../store/session";
import { databaseRef } from "../../../store/firebase";
import TSession, {
  SessionStatus,
  WaitingRoom,
  WaitingRoomParticipant,
  WaitingRoomStatus,
} from "../../../types/session";
import { WaitListInfo } from "../../../types/wait-list-info";
import { Provider, ProviderPath } from "../../../types/provider";
import { Appointment } from "../../../types/appointment";
import { ApptValue } from "../../../types/appt-value";
import { WaitlistActionButtons } from "../../elements/WaitlistActionButtons";
import { SessionlistActionButtons } from "../../elements/SessionlistActionButtons";
import { PermissionContext } from "../../context/permissionContext";

const StyledMainStatBody = styled(Col)`
  margin-top: 20px;
  & > div {
    flex-basis: 100%;
    &:first-child {
      margin-bottom: 20px;
    }
  }
  @media ${device.laptop} {
    display: flex;
    justify-content: space-between;
    & > div {
      flex-basis: 48%;
      &:first-child {
        margin-bottom: 0px;
      }
    }
  }
`;

const StyledSingleSchedule = styled.div`
  margin-top: 30px;
  @media ${device.laptop} {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledAppointmentDetail = styled.div`
  flex-basis: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  & div {
    display: flex;
    height: fit-content;
    align-items: center;
    & p {
      margin: 0px;
      color: ${theme.mutedColor};
      font-family: ${fontFamily.body};
      font-weight: 400;
      font-size: 10px;
      margin-left: 5px;
    }
  }
  & > p {
    margin: 0px;
    font-family: ${fontFamily.heading};
    font-size: 14px;
    font-weight: 500;
  }
  @media ${device.laptop} {
    margin: 0px;
    flex-basis: auto;
  }
`;

const StyledAppointmentsIcon = styled.div`
  color: ${(props) => props.color};
  font-size: 13px;
  @media ${device.laptop} {
    border-radius: 2px;
    display: grid;
    place-items: center;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid ${(props) => props.color};
    font-size: 1.1vw;
  }
`;

const StyledScheduledTime = styled.div`
  display: flex;
  height: fit-content;
  align-items: center;
  margin-left: 15px;
  & p {
    margin: 0px;
    &:first-child {
      font-family: ${fontFamily.heading};
      font-weight: 500;
      font-size: 14px;
    }
    &:nth-child(2) {
      margin-left: 5px;
      font-family: ${fontFamily.body};
      font-weight: 400;
      font-size: 10px;
      color: ${theme.mutedColor};
    }
  }
  @media ${device.laptop} {
    display: block;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
    & p {
      height: fit-content;
      &:nth-child(2) {
        margin-left: 0px;
      }
    }
  }
`;

const StyledTimeAndIcon = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 100%;
  @media ${device.laptop} {
    flex-basis: auto;
  }
`;
const StyledArrowRight = styled.div`
  height: fit-content;
  width: fit-content;
  background-color: ${theme.primaryColor};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 3px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
`;

const StyledWaitingIcon = styled(StyledAppointmentsIcon)`
  @media ${device.laptop} {
    padding: 10px 4px;
    border: 1px solid #7188c2;
  }
`;

const WaitingIcon = () => (
  <StyledWaitingIcon>
    <img src={waitingIcon1} alt="" />
  </StyledWaitingIcon>
);

const TimeAndIcon = (props: {
  timeIcon: JSX.Element;
  hideTime?: boolean;
  date?: string;
}) => (
  <StyledTimeAndIcon>
    {props.timeIcon}
    {!props.hideTime && (
      <StyledScheduledTime>
        <p>{props.date ? moment(props.date).format("dddd, hh:mm a") : ""}</p>
        <p>60mins</p>
      </StyledScheduledTime>
    )}
  </StyledTimeAndIcon>
);

const AppointmentDetail = (props: {
  appt?: ApptValue;
  providerName?: string;
}) => (
  <StyledAppointmentDetail>
    <p>
      <ScheduleOutlined /> {props.appt?.requestedService}
    </p>
    <p>
      <UserOutlined />{" "}
      {`${props.appt?.clientFirstName} ${props.appt?.clientLastName}`}
    </p>
    <p>
      <PhoneOutlined /> {props.appt?.clientPhoneNo}
    </p>
  </StyledAppointmentDetail>
);

const WaitDetail = (props: { wait?: WaitListInfo }) => (
  <StyledAppointmentDetail>
    <p>{props.wait?.patientName}</p>
    <p>{props.wait?.ServiceType}</p>
  </StyledAppointmentDetail>
);

const OngoingSessionDetail = (props: { session?: TSession }) => (
  <StyledAppointmentDetail>
    <p>{props.session?.patientName}</p>
    <p>{props.session?.requestedService}</p>
  </StyledAppointmentDetail>
);

const StyledArrowButton = (props: {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => (
  <StyledArrowRight onClick={props.onClick}>
    <TiArrowRight />
  </StyledArrowRight>
);

const SingleAppt = (props: {
  timeIcon: JSX.Element;
  button: JSX.Element;
  hideTime?: boolean;
  appt?: ApptValue;
  providerName?: string;
}) => (
  <StyledSingleSchedule>
    <TimeAndIcon
      timeIcon={props.timeIcon}
      hideTime={props.hideTime}
      date={props.appt?.appointmentDate}
    />
    <AppointmentDetail appt={props.appt} providerName={props.providerName} />
    {props.button}
  </StyledSingleSchedule>
);

const SingleWait = (props: {
  timeIcon: JSX.Element;
  button: JSX.Element;
  hideTime?: boolean;
  wait?: WaitListInfo;
}) => (
  <StyledSingleSchedule>
    <WaitDetail wait={props.wait} />
    {props.button}
  </StyledSingleSchedule>
);

const SingleOngoingSession = (props: {
  timeIcon: JSX.Element;
  button: JSX.Element;
  hideTime?: boolean;
  session?: TSession;
}) => (
  <StyledSingleSchedule>
    <OngoingSessionDetail session={props.session} />
    {props.button}
  </StyledSingleSchedule>
);

const WaitingList = (props: {
  waitList: Array<WaitListInfo>;
  acceptWait: (waitingRoomEntry: WaitListInfo | WaitingRoom) => Promise<void>;
  rejectWait: (path: string) => Promise<void>;
  visible: boolean;
}) => (
  <TitledCard
    title={"Wait List (".concat(props.waitList.length.toString()).concat(")")}
    titleFooter="Keep an eye on Clients waitlist"
    visible={props.visible}
  >
    {props.waitList.map((info: WaitListInfo) => (
      <SingleWait
        timeIcon={<WaitingIcon />}
        button={
          <WaitlistActionButtons
            info={info}
            acceptWait={props.acceptWait}
            rejectWait={props.rejectWait}
          />
        }
        wait={info}
      />
    ))}
  </TitledCard>
);

const OngoingSessionList = (props: {
  ongoingList: Array<TSession>;
  acceptToSession: (ongoingSessionEntry: TSession) => Promise<void>;
  completeSession: (ongoingSessionEntry: TSession) => Promise<void>;
  visible: boolean;
}) => (
  <TitledCard
    title={"Ongoing Session List ("
      .concat(props.ongoingList.length.toString())
      .concat(")")}
    titleFooter="Rejoin ongoing session."
    visible={props.visible}
  >
    {props.ongoingList.map((info: TSession) => (
      <SingleOngoingSession
        timeIcon={<WaitingIcon />}
        button={
          <SessionlistActionButtons
            info={info}
            acceptToSession={props.acceptToSession}
            completeSession={props.completeSession}
          />
        }
        session={info}
      />
    ))}
  </TitledCard>
);

const UpcomingEvents = (props: {
  appointments: Array<ApptValue>;
  provider?: Provider;
  visible: boolean;
  history: any;
}) => (
  <TitledCard
    title="Upcoming Appointments"
    titleFooter="Scheduled meetings and appointments with clients"
    visible={props.visible}
  >
    {props.appointments.map((value: ApptValue) => (
      <SingleAppt
        timeIcon={<TimeIcon borderColor="#36C298" color="#36C298" />}
        button={
          <StyledArrowButton
            onClick={() => {
              props.history.push(
                `/provider/schedule/create-appointment?appointmentId=${value.id}&patientId=${value.patientId}&providerId=${props.provider?.id}`
              );
            }}
          />
        }
        appt={value}
        providerName={props.provider?.fullName}
      />
    ))}
  </TitledCard>
);

export const Overview = () => {
  const { setCurrentPageName } = useScreenNameContext() as any;
  const [loader, showLoader] = useState(true);
  const { permission } = useContext(PermissionContext) as any;

  const history = useHistory();

  const [appointments, setAppointments] = useState<ApptValue[]>([]);
  const [updatedWaitlist, setUpdatedWaitlist] = useState<WaitListInfo[]>([]);
  const [provider, setProvider] = useState<Provider>();
  const [completedSessionCount, setCompletedSessionCount] = useState(0);
  const [upcomingSessionCount, setUpcomingSessionCount] = useState(0);
  const [totalAppointments, setTotalAppointments] = useState(0);
  const [showEmptyAppointment, setShowEmptyAppointment] = useState(true);
  const [averageWaitTime, setAverageWaitTime] = useState("");
  const [ongoingSession, setOngoingSession] = useState<TSession[]>([]);

  useEffect(() => {
    setCurrentPageName("Overview");
    const providerStore = new ProviderStore();
    const sessionStore = new SessionStore();

    if (permission && !provider) {
      providerStore
        .fetchProvider(permission.email)
        .then((providerObj: Provider) => {
          setProvider(providerObj);

          sessionStore
            .getSessionsByProvider(providerObj.id)
            .then((sessions) => {
              let completedSession = 0;
              let totalAppointmentAndSessionDifference = 0;
              Object.values(sessions).forEach((session) => {
                const isSessionForThisWeek = moment(
                  session.sessionStartDate
                ).isSame(new Date(), "week");
                if (
                  isSessionForThisWeek &&
                  session.sessionStatus === SessionStatus.ENDED
                ) {
                  completedSession += 1;
                  if (session.appointmentDate && session.sessionEndDate) {
                    const waitroomStartTime = moment(session.waitroomStartTime);
                    const sessionStartTime = moment(session.sessionStartDate);
                    totalAppointmentAndSessionDifference = waitroomStartTime.diff(
                      sessionStartTime,
                      "minutes"
                    );
                  }
                }
              });

              setCompletedSessionCount(completedSession);
              // calcuate average wait time
              const averageWaitTimeInMinutes =
                totalAppointmentAndSessionDifference > 0
                  ? (
                      totalAppointmentAndSessionDifference / completedSession
                    ).toFixed(2)
                  : 0;
              setAverageWaitTime(averageWaitTimeInMinutes.toString());

              providerStore
                .fetchProviderAppointments(providerObj.id)
                .then((responses: any) => {
                  const appointmentsData: ApptValue[] = [];
                  let totalWeeklyAppointments = 0;
                  let upcomingAppointments = 0;
                  Object.values(responses).forEach((response) => {
                    const appointment = response as Appointment;

                    Object.keys(appointment).forEach((apptKey) => {
                      const appointmentValue = appointment[
                        apptKey
                      ] as ApptValue;
                      const appointmentDate = moment(
                        appointmentValue.appointmentDate
                      );
                      if (!appointmentDate.isBefore()) {
                        appointmentsData.push(appointmentValue);
                        upcomingAppointments += 1;
                      }

                      const isSessionForThisWeek = moment(
                        appointmentDate
                      ).isSame(new Date(), "week");
                      if (isSessionForThisWeek) {
                        totalWeeklyAppointments += 1;
                      }
                    });
                  });

                  const sortedAppointments = appointmentsData.sort(
                    (a: any, b: any) =>
                      new Date(a.appointmentDate).getTime() -
                      new Date(b.appointmentDate).getTime()
                  );
                  setAppointments(sortedAppointments);
                  setTotalAppointments(totalWeeklyAppointments);
                  setShowEmptyAppointment(totalWeeklyAppointments === 0);
                  setUpcomingSessionCount(upcomingAppointments);
                  showLoader(false);
                });
            });
        });
    }
  }, [permission, provider]);

  const addOrRemoveWaitlistItem = useCallback(
    (waitrooms: WaitingRoom[]) => {
      const newWaitListInfos: WaitListInfo[] = waitrooms.map((w: any) => ({
        id: w.id,
        ProviderName: provider?.fullName!,
        patientName: w.patientName,
        ServiceType: w.requestedService,
        waitRoomPath: w.path,
        paymentId: w.paymentId,
        status: w.status,
        waitingRoomParticipant: w.waitingRoomParticipant,
      }));
      if (newWaitListInfos.length) {
        setUpdatedWaitlist((prevList: any) =>
          [...newWaitListInfos, ...prevList].filter(
            (v, i, a) => a.findIndex((t) => t.id === v.id) === i
          )
        );
      }
    },
    [provider]
  );

  const AddOrRemoveOngoingSessionItem = useCallback(
    (onGoingSessions: TSession[]) => {
      const newOngoingSessionList: TSession[] = onGoingSessions.map((s) => ({
        paymentId: s.paymentId,
        providerId: s.providerId,
        sessionStatus: s.sessionStatus,
        providerName: s.providerName,
        patientEmail: s.patientEmail,
        providerEmail: s.providerEmail,
        participants: s.participants,
        location: s.location,
        patientName: s.patientName,
        amount: s.amount,
        sessionStartDate: s.sessionStartDate,
        requestedService: s.requestedService,
        waitroomStartTime: s.waitroomStartTime,
        waitroomStartTimeTs: s.waitroomStartTimeTs,
      }));

      if (newOngoingSessionList.length) {
        setOngoingSession((prevList: any) =>
          [...newOngoingSessionList, ...prevList].filter(
            (v, i, a) => a.findIndex((t) => t.paymentId === v.paymentId) === i
          )
        );
      }
    },
    [provider]
  );

  useEffect(() => {
    async function getWaitlist() {
      if (provider) {
        const waitListPath = databaseRef.child(`waiting_room/${provider.id}`);
        const handleWaitlistChanges = (snapshot: any) => {
          const updatedWaitingRoom = snapshot.val() as WaitingRoom;
          addOrRemoveWaitlistItem([updatedWaitingRoom]);
        };
        const waitlistDoc = await waitListPath.once("value");
        if (waitlistDoc.exists()) {
          const waitlistDocItems = (Object.values(
            waitlistDoc.val()
          ) as WaitingRoom[]).filter(
            (w) => w.status === WaitingRoomStatus.PENDING
          );
          if (waitlistDocItems.length) {
            addOrRemoveWaitlistItem(waitlistDocItems as WaitingRoom[]);
          }
        }
        waitListPath.on("child_added", handleWaitlistChanges);
        waitListPath.on("child_changed", handleWaitlistChanges);
      }
    }
    getWaitlist();
  }, [provider]);

  useEffect(() => {
    async function getOngoingSessionList() {
      if (provider) {
        const sessionListPath = databaseRef.child("session");

        const handleOngoingSessionlistChanges = (snapshot: any) => {
          const updatedOngoingSession = snapshot.val() as TSession;
          AddOrRemoveOngoingSessionItem([updatedOngoingSession]);
        };

        const ongoingSessionlistDoc = await sessionListPath.once("value");
        if (ongoingSessionlistDoc.exists()) {
          const ongoinglistDocItems = (Object.values(
            ongoingSessionlistDoc.val()
          ) as TSession[]).filter(
            (s) =>
              s.providerId === provider.id &&
              s.sessionStatus === SessionStatus.STARTED
          );
          if (ongoinglistDocItems.length) {
            AddOrRemoveOngoingSessionItem(ongoinglistDocItems as TSession[]);
          }
        }

        sessionListPath.on("child_added", handleOngoingSessionlistChanges);
        sessionListPath.on("child_changed", handleOngoingSessionlistChanges);
      }
    }
    getOngoingSessionList();
  }, [provider]);

  async function joinSession(ongoingSessionEntry: TSession) {
    history.push(ProviderPath.SESSION, {
      sessionDetails: {
        paymentId: ongoingSessionEntry.paymentId,
        participant: {
          name: provider?.fullName!,
          id: provider?.id!,
          isProvider: true,
        },
      },
    });
  }

  async function endSession(ongoingSessionEntry: TSession) {
    const sessionToUpdate = { ...ongoingSessionEntry };
    sessionToUpdate.sessionStatus = SessionStatus.ENDED;
    sessionToUpdate.sessionEndDate = new Date();
    await new SessionStore(undefined, sessionToUpdate.paymentId).saveSession(
      sessionToUpdate
    );
  }

  async function acceptPatient(waitingRoomEntry: WaitListInfo | WaitingRoom) {
    const waitListPath = databaseRef.child(
      `waiting_room/${provider?.id}/${
        (waitingRoomEntry as WaitListInfo).waitRoomPath
      }`
    );
    const startTime = new Date();
    await waitListPath.update({
      status: WaitingRoomStatus.ACCEPTED,
      sessionStartDate: startTime,
      sessionStartDateTs: startTime.getTime(),
    });

    // Go to screen
    if (
      waitingRoomEntry.waitingRoomParticipant === WaitingRoomParticipant.SINGLE
    ) {
      const fectedSession = await new SessionStore(
        undefined,
        waitingRoomEntry.paymentId
      ).getSession();

      if (fectedSession) {
        const sessionToUpdate = { ...fectedSession };
        sessionToUpdate.sessionStatus = SessionStatus.STARTED;
        await new SessionStore(
          undefined,
          sessionToUpdate.paymentId
        ).saveSession(sessionToUpdate);
      }

      history.push(ProviderPath.SESSION, {
        sessionDetails: {
          paymentId: waitingRoomEntry.paymentId,
          participant: {
            name: provider?.fullName!,
            id: provider?.id!,
            isProvider: true,
          },
        },
      });
    }
  }

  async function rejectPatient(path: string) {
    const waitListPath = databaseRef.child(
      `waiting_room/${provider?.id}/${path}`
    );
    await waitListPath.update({ status: WaitingRoomStatus.REJECTED });
  }

  const pendingWaitlist = updatedWaitlist.filter(
    (w: any) => w.status === WaitingRoomStatus.PENDING
  );

  const waitingOngoingList = ongoingSession.filter(
    (s: any) =>
      s.providerId === provider!.id && s.sessionStatus === SessionStatus.STARTED
  );

  return (
    <>
      <StatSummarySegment
        apptCnt={totalAppointments}
        upcomingCnt={upcomingSessionCount}
        sessionCnt={completedSessionCount}
        AvgTime={averageWaitTime}
      />
      <StyledMainStatBody xs={{ span: 24 }}>
        <div>
          <QuickAction
            visible={false}
            createAppt={() => {
              history.push(`/provider/schedule/create-appointment`, {
                provider,
              });
            }}
          />

          <OngoingSessionList
            visible={waitingOngoingList.length > 0}
            ongoingList={waitingOngoingList}
            acceptToSession={joinSession}
            completeSession={endSession}
          />
          <WaitingList
            visible={pendingWaitlist.length > 0}
            waitList={pendingWaitlist}
            acceptWait={acceptPatient}
            rejectWait={rejectPatient}
          />
          <EmptyWaitingList visible={pendingWaitlist.length === 0} />
        </div>
        <div>
          {showEmptyAppointment ? (
            <>
              <EmptyUpcomingEvents provider={provider} visible />
            </>
          ) : (
            <>
              <UpcomingEvents
                appointments={appointments}
                provider={provider}
                visible
                history={history}
              />
            </>
          )}
        </div>
      </StyledMainStatBody>
      <Loader visible={loader} text="Loading ... " />
    </>
  );
};
