import moment from "moment";
import { databaseRef } from "./firebase";
import TSession, { SessionStatus } from "../types/session";
import PaymentStore from "./payment";
import logger from "../utils/logger";
import { Payment } from "../types/payment";
import { AppointmentType } from "../types/enum";

export default class SessionStore {
  paymentId?: string;

  payment?: Payment;

  private path: string;

  constructor(payment?: Payment, paymentId?: string) {
    this.payment = payment;
    this.paymentId = paymentId || payment?.id;
    this.path = "session";
  }

  async createOrJoinSession(
    joinSession: boolean = false,
    participantName?: string,
    participantId?: string
  ): Promise<{
    session: TSession;
    name: string;
    id: string;
  } | null> {
    if (!this.paymentId) {
      return null;
    }
    const sessionDoc = await databaseRef
      .child(`${this.path}/${this.paymentId}`)
      .once("value");
    this.payment = !this.payment
      ? await new PaymentStore().fetchPayment(this.paymentId)
      : this.payment;
    const sessionParticipantName = participantName || this.payment?.patientName;
    const sessionParticipantId = participantId || this.payment?.patientId;
    if (!sessionDoc.exists()) {
      // Get payment doc and create a new session.
      if (this.paymentId) {
        const startTime = new Date();
        const newSession: TSession = {
          paymentId: this.paymentId,
          providerId: this.payment?.providerId,
          sessionStartDate: startTime,
          sessionStartDateTs: startTime.getTime(),
          waitroomStartTime: startTime,
          waitroomStartTimeTs: startTime.getTime(),
          appointmentDate: this.payment?.appointmentDate,
          appointmentDateTs: moment(this.payment?.appointmentDate).valueOf(),
          appointmentType:
            this.payment?.appointmentType || AppointmentType.TeleConsult,
          amount: this.payment?.amount,
          sessionStatus: SessionStatus.PENDING,
          providerName: this.payment?.providerName,
          patientName: this.payment?.patientName,
          patientEmail: this.payment?.patientEmail,
          providerEmail: this.payment?.providerEmail,
          participants: [],
          location: this.payment?.location,
          requestedService: this.payment?.requestedService,
        };
        if (joinSession) {
          newSession.participants.push({
            id: sessionParticipantName,
            name: sessionParticipantId,
            joined: new Date(),
          });
        }
        await this.saveSession(newSession);
        return {
          session: newSession,
          name: sessionParticipantName,
          id: sessionParticipantId,
        };
      }
    } else if (sessionDoc.exists()) {
      const existingSession = sessionDoc.val() as TSession;
      if (existingSession.sessionStatus === SessionStatus.ENDED) {
        return null;
      }
      const isParticipantInSession = (existingSession.participants || []).some(
        (p) => p.id === participantId
      );
      if (!isParticipantInSession && joinSession) {
        existingSession.participants.push({
          id: sessionParticipantName,
          name: sessionParticipantId,
          joined: new Date(),
        });
        await this.saveSession(existingSession);
      }
      return {
        session: existingSession,
        name: sessionParticipantName,
        id: sessionParticipantId,
      };
    }
    return null;
  }

  async getSession(): Promise<TSession | null> {
    const sessionDoc = await databaseRef
      .child(`${this.path}/${this.paymentId}`)
      .once("value");
    if (sessionDoc.exists()) {
      return sessionDoc.val() as TSession;
    }
    return null;
  }

  async saveSession(session: TSession) {
    await databaseRef
      .child(`${this.path}/${session.paymentId}`)
      .update(session);
  }

  async endSession(paymentId: string) {
    await databaseRef.child(`${this.path}/${paymentId}`).update({
      sessionStatus: SessionStatus.ENDED,
      sessionEndDate: new Date(),
      sessionEndDateTs: new Date().getTime(),
    });
  }

  async getSessionsByProviderAndStatus(
    providerHash: string,
    status: SessionStatus
  ): Promise<TSession[]> {
    let responses = {} as TSession[];
    const providerHistory: TSession[] = [];
    try {
      const sessionDoc = await databaseRef
        .child(`${this.path}`)
        .orderByChild("providerId")
        .equalTo(providerHash)
        .once("value");

      if (sessionDoc.exists()) {
        responses = sessionDoc.val() as Array<TSession>;
        Object.values(responses).forEach((response) => {
          if (response.sessionStatus === status) {
            providerHistory.push(response);
          }
        });
      }
    } catch (err) {
      logger.error(err.message);
    }

    return providerHistory;
  }

  async getSessionsByProvider(providerHash: string): Promise<TSession[]> {
    let sessions: TSession[] = [];
    try {
      const sessionDoc = await databaseRef
        .child(`${this.path}`)
        .orderByChild("providerId")
        .equalTo(providerHash)
        .once("value");

      if (sessionDoc.exists()) {
        sessions = sessionDoc.val() as Array<TSession>;
      }
    } catch (err) {
      logger.error(err.message);
    }
    return sessions;
  }

  async getSessionsByDate(startDate: any, endDate: any): Promise<TSession[]> {
    let sessions: TSession[] = [];
    try {
      const sessionDoc = await databaseRef
        .child(`${this.path}`)
        .orderByChild("sessionStartDateTs")
        .startAt(startDate)
        .endAt(endDate)
        .once("value");
      if (sessionDoc.exists()) {
        sessions = sessionDoc.val() as Array<TSession>;
      }
    } catch (err) {
      logger.error(err.message);
    }
    return sessions;
  }

  async getAllSessions(): Promise<TSession[]> {
    let sessions: TSession[] = [];
    try {
      const sessionDoc = await databaseRef
        .child(`${this.path}`)
        .orderByChild("sessionStartDateTs")
        .once("value");
      if (sessionDoc.exists()) {
        sessions = sessionDoc.val() as Array<TSession>;
      }
    } catch (err) {
      logger.error(err.message);
    }
    return sessions;
  }
}
