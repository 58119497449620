import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { BackButton } from "../atoms/BackButton";
import { ReportContainer } from "./ReportContainer";
import { RevenueByProviderReportsBody } from "./RevenueByProviderReportsBody";
import {
  StyledGroupHead,
  ReportGroupHeader,
  ReportGroupFooter,
} from "./ReportAssets";
import { PermissionContext } from "../../context/permissionContext";
import { Loader } from "../../elements/Loader";
import PaymentStore from "../../../store/payment";
import { Payment } from "../../../types/payment";
import { formatWithCurrency, getUniqueId } from "../../../utils/hash";
import { PaymentStatus, Role } from "../../../types/enum";

export const RevenueByProviderReports = () => {
  const [loader, showLoader] = useState(true);
  const dateFormat = "MM-DD-YYYY";
  const [reportStartDate, setReportStartDate] = useState(
    moment().startOf("month")
  );
  const [reportEndDate, setReportEndDate] = useState(moment().endOf("month"));
  const reportTitle = "Revenue by Provider";
  const reportFileName = "revenue-by-provider-report.csv";
  const groupByFilter = ["Provider", "Date"];
  const [paymentData, setPaymentData] = useState([] as any);
  const [reportBody, setReportBody] = useState([] as any);
  const reportHeaders = ["Service", "Location", "Date & Time", "Copay"];
  const defaultCurrency = "USD";

  const history = useHistory();
  const { permission } = useContext(PermissionContext) as any;

  if (!permission || permission.role === Role.PROVIDER) {
    history.push("/provider/overview", { permission });
  }

  const processReportData = (orderedPayments: any[]) => {
    const headers = reportHeaders.map((item, index) => {
      const key = `thkey-${index}-${getUniqueId()}`;
      return <th key={key}>{item}</th>;
    });

    const groupedPayments = _.groupBy(orderedPayments, "provider");
    const paymentsElements: any[] = [];
    let grandTotal = 0;
    Object.keys(groupedPayments).forEach((provider: any) => {
      // Group header, by provider
      paymentsElements.push(
        <tr>
          <td colSpan={7}>
            <ReportGroupHeader>{provider}</ReportGroupHeader>
          </td>
        </tr>
      );

      // Group row header
      paymentsElements.push(<StyledGroupHead>{headers}</StyledGroupHead>);

      const groupedPayment = groupedPayments[provider];

      let totalAmount = 0;

      Object.values(groupedPayment).forEach((payment: any) => {
        const key = `curr-data-${getUniqueId()}`;
        paymentsElements.push(
          <RevenueByProviderReportsBody key={key} data={payment} />
        );
        totalAmount += parseFloat(payment.amount);
      });

      grandTotal += totalAmount;

      paymentsElements.push(
        <tr>
          <td colSpan={3}>
            <ReportGroupFooter> Total </ReportGroupFooter>
          </td>
          <td>
            <ReportGroupFooter>
              {formatWithCurrency(totalAmount, defaultCurrency)}
            </ReportGroupFooter>
          </td>
        </tr>
      );
    });
    paymentsElements.push(
      <tr>
        <td colSpan={3}>
          <ReportGroupFooter> Grand Total </ReportGroupFooter>
        </td>
        <td>
          <ReportGroupFooter>
            {formatWithCurrency(grandTotal, defaultCurrency)}
          </ReportGroupFooter>
        </td>
      </tr>
    );

    setReportBody(paymentsElements);
    showLoader(false);
  };

  const fetchPaymentsByDate = (startDate: any, endDate: any) => {
    showLoader(true);
    const paymentStore = new PaymentStore();
    paymentStore
      .fetchPaymentsByDate(startDate.valueOf(), endDate.valueOf())
      .then((payments: Payment[]) => {
        const parsedPayments = [] as any[];
        Object.values(payments).forEach((payment: Payment) => {
          if (payment.status === PaymentStatus.Completed) {
            const paymentDate = moment(payment.paymentDate);
            parsedPayments.push({
              client: payment.patientName,
              service: payment.requestedService,
              location: payment.location,
              formattedDate: paymentDate.format("MMMM, D yyyy"),
              timeStamp: paymentDate.format("HH:mm"),
              amount: payment.amount,
              copay: formatWithCurrency(payment.amount, payment.currency),
              provider: payment.providerName,
              date: paymentDate.format("YYYY-MM-DD"), // format date for easy sorting
            });
          }
        });

        // group data by date and order desc and group by date
        const orderedPayments = _.orderBy(
          parsedPayments,
          ["date", "timeStamp"],
          "desc"
        );
        setPaymentData(orderedPayments);
        processReportData(orderedPayments);
      });
  };

  useEffect(() => {
    fetchPaymentsByDate(reportStartDate, reportEndDate);
  }, []);

  const filterByDate = (
    startDate: any,
    endDate: any,
    processDataHandler: any
  ) => {
    setReportStartDate(startDate);
    setReportEndDate(endDate);
    showLoader(true);
    if (startDate && endDate) {
      fetchPaymentsByDate(startDate, endDate);
    } else {
      processDataHandler(paymentData);
    }
  };

  return (
    <>
      <BackButton />
      <ReportContainer
        reportTitle={reportTitle}
        dateFormat={dateFormat}
        showStartDate
        showEndDate
        showGroupBy={false}
        showApply
        showPrint
        showExport
        groupByList={groupByFilter}
        reportBody={reportBody}
        data={paymentData}
        reportFileName={reportFileName}
        filterHandler={filterByDate}
        dataHandler={processReportData}
        reportStartDate={reportStartDate}
        reportEndDate={reportEndDate}
      />

      <Loader visible={loader} text={`Loading ${reportTitle} ...`} />
    </>
  );
};
