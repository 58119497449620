import { Col, Divider, Drawer, Row } from "antd";
import React, { useEffect, useState } from "react";
import { FiMenu, FiX, FiUsers } from "react-icons/fi";
import { FaSignOutAlt } from "react-icons/fa";
import {
  FcBarChart,
  FcCalendar,
  FcConferenceCall,
  FcHome,
  FcAreaChart,
  FcMoneyTransfer,
  FcSettings,
} from "react-icons/fc";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../assets/breakbpoints";
import { fontFamily } from "../../assets/fontFamily";
import { Logo } from "../../assets/svgs/Logo";
import { theme } from "../../assets/theme";
import { firebaseAuth, getUserPermissionFunction } from "../../store/firebase";
import ProviderStore from "../../store/provider";
import { Role } from "../../types/enum";
import { Provider, ProviderPath } from "../../types/provider";
import { PermissionContext } from "../context/permissionContext";
import { ScreenNameContext } from "../context/screenNameContext";
import { WaitlistNotifications } from "./WaitlistNotifications";
import { getUniqueId } from "../../utils/hash";

declare const window: any;

const StyledUpcomingEventScaffold = styled(Row)`
  /*  width: 100vw;
    min-height: 100vh; */
  background-color: #f7fafc;
  /*  margin: 0 auto; */
  /*    padding: 20px 0px; */
  /* display: flex;   */
`;

const StyledMainContainer = styled(Col)`
  /*  width: 100%; */

  padding: 20px 20px;

  @media ${device.laptop} {
    /*  width: 87%; */
    padding: 0px 60px 40px 40px;
  }
`;

const StyledSideBar = styled(Col)`
  display: none;
  background-color: ${theme.primaryColor};
  min-height: 100vh;

  @media ${device.laptop} {
    /* width: 17%; */
    display: inline-block;
  }
`;

const StyledHeader = styled(Col)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* width: 100%; */

  /*
    margin: 0 auto; */

  @media ${device.laptop} {
    display: none;
  }
`;

const StyledNav = styled.div<{ isactive?: boolean }>`
  background-color: ${(props) =>
    props.isactive === true ? `${theme.white}` : "transparent"};
  width: 70%;
  display: flex;
  align-items: center;
  border-radius: 0px 20px 20px 0px;
  color:  ${(props) =>
    props.isactive === true ? `${theme.secondaryColor}` : `${theme.secondaryColor}`};
  padding: 5px 0px 5px 25px;
  margin-top: 10px;
  font-size: 14px;

  @media ${device.laptop} {
    font-size: 1.2vw;
    padding: 10px 0px 10px 25px;
    border-radius: 0px 25px 25px 0px;
  }
`;

const StyledLink = styled(Link)<{ isactive?: boolean }>`
  color: ${(props) =>
    props.isactive === true ? `${theme.secondaryColor}` : `${theme.white}`};
  margin-left: 20px;
  font-family: ${fontFamily.manrope};
  font-weight: ${(props) => (props.isactive === true ? "700" : "normal")};
  font-size: 14px;
  &:focus,
  &:hover {
    color: ${(props) =>
      props.isactive === true ? `${theme.secondaryColor}` : `${theme.white}`};
  }

  @media ${device.laptop} {
    font-size: 1vw;
  }
`;

const StyledTitle = styled.h1`
  font-family: ${fontFamily.heading};
  color: ${theme.secondaryColor};
  font-size: 28px;
  text-align: center;
  margin-top: 10px;

  @media ${device.laptop} {
    font-size: 2vw;
  }
`;

const StyledTitleHeaderDiv = styled(Col)`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  justify-content: space-between;
  margin-top: calc(10px + (1vw));
`;

const StyledLogoDiv = styled.div`
  margin-left: 10px;
  margin-top: 40px;
  margin-bottom: 50px;
  width: 40%;
`;

export const ProviderModule = (props: { children: any }) => {
  const [visibleSideBar, setVisibleSideBar] = useState(false);
  const [currentPageName, setCurrentPageName] = useState("Overview");
  const [provider, setProvider] = useState({} as Provider);
  const history = useHistory();
  const browserLocation = useLocation();
  const [permission, setPermission] = useState(
    (browserLocation.state as any)?.permission as any
  );
  const [sidebarNav, setSidebarNav] = useState([] as any);
  const [isNavigationSet, setIsNavigationSet] = useState(false);

  useEffect(() => {
    async function getAuthProvider() {
      firebaseAuth.onAuthStateChanged(async (user: any) => {
        if (!user) {
          history.push("/");
        } else {
          const providerStore = new ProviderStore();
          setProvider(await providerStore.fetchProvider(user.email));
        }
      });
    }
    getAuthProvider();
  }, []);

  const baseNavigationsLength = 4;
  let navigations = [
    {
      name: "Dashboard",
      icon: <FcHome />,
      path: ProviderPath.OVERVIEW,
    },
    {
      name: "Appointments",
      icon: <FcCalendar />,
      path: ProviderPath.SCHEDULE,
    },
    {
      name: "Clients",
      icon: <FcConferenceCall />,
      path: ProviderPath.PATIENT,
    },
    {
      name: "Analytics",
      icon: <FcAreaChart />,
      path: ProviderPath.ANALYTICS,
    },
  ];

  const adminNavigations = [
    
    {
      name: "Claims",
      icon: <FcMoneyTransfer />,
      path: ProviderPath.PROVIDER,
    },
    {
      name: "Reports",
      icon: <FcBarChart />,
      path: ProviderPath.REPORTS,
    },
    {
      name: "My Team",
      icon: <FiUsers />,
      path: ProviderPath.PROVIDER,
    },
    {
      name: "Settings",
      icon: <FcSettings />,
      path: ProviderPath.SETTINGS,
    },
  ];

  const handleOpenSideBar = () => {
    setVisibleSideBar(true);
  };

  const onCloseSideBar = () => {
    setVisibleSideBar(false);
  };

  const setNavigationsByRole = (
    userPermission: any,
    name?: string,
    path?: string
  ) => {
    setIsNavigationSet(true);
    const userRole = userPermission.role.toString();
    if (
      userRole === Role.OWNER.toString() ||
      userRole === Role.ADMIN.toString()
    ) {
      if (navigations.length === baseNavigationsLength) {
        navigations = navigations.concat(adminNavigations);
      }
    }
    const sideBarNavigations = navigations.map(
      (
        item: {
          name: string;
          icon: JSX.Element;
          path: ProviderPath;
        },
        index: any
      ) => {
        const key = `sidebarnav-${index}-${getUniqueId()}`;
        return (
          <StyledNav
            onClick={() => window.onNavClick(item.name, item.path)}
            isactive={item.name === (name || currentPageName)}
            key={key}
          >
            {item.icon}
            <StyledLink
              to={item.path}
              isactive={item.name === (name || currentPageName)}
            >
              {item.name}
            </StyledLink>
          </StyledNav>
        );
      }
    );
    setSidebarNav(sideBarNavigations);
    if (path) {
      setVisibleSideBar(false);
      history.push(path);
    }
  };

  window.onNavClick = (name: string, path: string) => {
    setCurrentPageName(name);
    setNavigationsByRole(permission, name, path);
  };

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(async (user: any) => {
      if (user && (!isNavigationSet || !permission)) {
        const email = firebaseAuth.currentUser?.email! || permission?.email;
        if (permission) {
          setIsNavigationSet(true);
          setNavigationsByRole(permission);
        } else if (email) {
          const getUserPermission = getUserPermissionFunction();
          getUserPermission({ email }).then((response: any) => {
            const userPermission = response.data!;
            if (userPermission) {
              setIsNavigationSet(true);
              setPermission(userPermission);
              setNavigationsByRole(userPermission);
            }
          });
        }
      } else if (!user) {
        history.push("/");
      }
    });
  });

  return (
    <ScreenNameContext.Provider value={{ setCurrentPageName }}>
      <PermissionContext.Provider value={{ permission }}>
        <StyledUpcomingEventScaffold>
          <StyledSideBar lg={{ span: 4 }}>
            <StyledLogoDiv>
              <Logo />
            </StyledLogoDiv>
            {sidebarNav}
            <Divider />
            <StyledNav isactive={false}>
              <FaSignOutAlt />
              <StyledLink
                onClick={async () => {
                  await firebaseAuth.signOut();
                  history.push("/");
                }}
                to="#"
                isactive={false}
              >
                Logout
              </StyledLink>
            </StyledNav>
          </StyledSideBar>
          <StyledMainContainer lg={{ span: 20 }}>
            <Row>
              <StyledHeader xs={{ span: 24 }}>
                <StyledLogoDiv style={{ width: "20%" }}>
                  <Logo />
                </StyledLogoDiv>
                <FiMenu onClick={handleOpenSideBar} size="35" />
              </StyledHeader>
              <StyledTitleHeaderDiv xs={{ span: 24 }}>
                <StyledTitle>{currentPageName}</StyledTitle>
                {provider.id && (
                  <WaitlistNotifications
                    providerId={provider.id}
                    fullName={provider.fullName}
                  />
                )}
              </StyledTitleHeaderDiv>
              <Divider />
              {props.children}
              <Drawer
                title={
                  <StyledLogoDiv>
                    <Logo />
                  </StyledLogoDiv>
                }
                placement="left"
                closable={false}
                onClose={onCloseSideBar}
                visible={visibleSideBar}
                closeIcon={<FiX />}
                bodyStyle={{ padding: "0px", position: "relative" }}
              >
                {sidebarNav}
                <Divider />
                <StyledNav isactive={false}>
                  <FaSignOutAlt />
                  <StyledLink
                    onClick={async () => {
                      await firebaseAuth.signOut();
                      history.push("/");
                    }}
                    isactive={false}
                    to="#"
                  >
                    Logout
                  </StyledLink>
                </StyledNav>
              </Drawer>
            </Row>
          </StyledMainContainer>
        </StyledUpcomingEventScaffold>
      </PermissionContext.Provider>
    </ScreenNameContext.Provider>
  );
};
