import React from "react";
import styled from "styled-components";
import { Input } from "antd";
import { InputDiv } from "./InputDiv";

const StyledTextInput = styled(Input)`
  width: 100%;
`;

export const TextInput = (props: {
  onChange: any;
  type: string;
  placeholder: string;
  onKeyPress?: any;
  onKeyUp?: any;
  maxLength?: number;
  id?: string;
}) => (
  <InputDiv>
    <StyledTextInput
      maxLength={props.maxLength}
      placeholder={props.placeholder}
      bordered={false}
      type={props.type}
      onChange={props.onChange}
      onKeyPress={props.onKeyPress}
      onKeyUp={props.onKeyUp}
      id={props.id}
    />
  </InputDiv>
);
