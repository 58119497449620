import React, { useState } from "react";

export const Logo = () => {
  const [logoDimension] = useState({
    width: 150,
    height: 40,
  });

  return (
    <img
      src="../medcon-logo-2.png"
      alt="Medcon Logo"
      width={logoDimension.width}
      height={logoDimension.height}
    />
  );
};
