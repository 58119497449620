import moment from "moment-timezone";

/**
 * Get full time zone name
 * @param timeZone
 */
function getFullTimeZoneName(timeZone?: string) {
  let fullName = "America/New_York";
  if (timeZone === "EST") fullName = "America/New_York";
  else if (timeZone === "EDT") fullName = "America/New_York";
  else if (timeZone === "PDT") fullName = "America/Los_Angeles";
  else if (timeZone === "PST") fullName = "America/Los_Angeles";
  return fullName;
}

export function getTodayDate(timeZone?: string, format: string = "YYYY-MM-DD") {
  if (timeZone !== undefined) {
    const fullTimeZoneName = getFullTimeZoneName(timeZone);
    return moment().tz(fullTimeZoneName).startOf("day").format(format);
  }
  return moment().startOf("day").format(format);
}

export function getCurrentDateTime(timeZone?: string) {
  if (timeZone !== undefined) {
    const fullTimeZoneName = getFullTimeZoneName(timeZone);
    return moment().tz(fullTimeZoneName);
  }
  return moment();
}

export function isDateValid(
  dateTime: any,
  granularity: any = "Day",
  format: string = "YYYY-MM-DDTHH:mm:ss.SSSZ"
) {
  const date = moment.utc(dateTime, format);
  const currentDate = moment.utc(format);
  const isValid = date.isValid() && !date.isAfter(currentDate, granularity);
  return isValid;
}

export function isDateInFuture(dateTime: any) {
  const date = moment.utc(dateTime);
  const isValid = date.isValid() && !date.isBefore();
  return isValid;
}

export function getDateParts(dateTime: any) {
  const date = moment(dateTime);
  // momentjs returns the month starting with index 0
  // https://momentjs.com/docs/#/get-set/month/
  const month = date.month() + 1;
  // get the last two digits of the year
  const year = date.year().toString().slice(-2);
  const dateParts = [month.toString(), year];
  return dateParts;
}

export function isDateToday(dateTime: any, timeZone: string) {
  const appointmentDay = moment(dateTime);
  const currentDateTime = getCurrentDateTime(timeZone);
  const isToday =
    appointmentDay.isSameOrAfter(currentDateTime) &&
    appointmentDay.day() === currentDateTime.day();
  return isToday;
}

export function getFormattedDate(dateTime: any, format: string = "DD/MM/YYYY") {
  const date = moment(dateTime);
  return date.format(format);
}

export function getFormattedTime(dateTime: any, format: string = "hh:mm a") {
  const date = moment(dateTime);
  return date.format(format);
}
