import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  match,
  Redirect,
} from "react-router-dom";
import { CheckinFailed } from "./components/patient/CheckinFailed";
import { CheckinSuccess } from "./components/patient/CheckinSuccess";
import { PatientSignIn } from "./components/patient/PatientSignIn";
import { Pay } from "./components/patient/Pay";
import { SelectProvider } from "./components/patient/SelectProvider";
import { WaitingRoom } from "./components/patient/waiting-room/WaitingRoom";
import { WelcomeScreen } from "./components/shared/Welcome";
import { RegisterScreen } from "./components/shared/Register";
import { OverviewContainer } from "./components/provider/overview/OverviewContainer";
import { ScheduleContainer } from "./components/provider/schedule/ScheduleContainer";
import { SessionRoom } from "./components/shared/session-room/SessionRoom";
import { Analytics } from "./components/provider/Analytics";
import { Meetings } from "./components/provider/Meetings";
import { Permissions } from "./components/provider/Permissions";
import { Settings } from "./components/provider/Settings";
import { PermissionsUpdate } from "./components/provider/PermissionsUpdate";
import { Reports } from "./components/provider/Reports";
import { SessionDetailsReports } from "./components/provider/reports/SessionDetails";
import { InPersonSessionDetailsReports } from "./components/provider/reports/InPersonSessionDetails";
import { PaymentDetailsReports } from "./components/provider/reports/PaymentCollected";
import { RevenueByProviderReports } from "./components/provider/reports/RevenueByProvider";
import { AppointmentsByProviderReports } from "./components/provider/reports/AppointmentsByProvider";
import { Clients } from "./components/provider/Clients";
import { Providers } from "./components/provider/Providers";
import { ProviderPath } from "./types/provider";
import { ProviderModule } from "./components/provider/ProviderModule";
import { getUserUniqueId } from "./utils/hash";
import { initializePendo } from "./utils/web-analytics";
import { CreateMeetingForm } from "./components/provider/CreateMeeting";
import { GroupMeetingRoom } from "./components/shared/session-room/GroupMeetingRoom";

const PatientRouter = ({ match: routeMatch }: { match: match }) => (
  <Switch>
    <Route path={`${routeMatch.path}/signin`}>
      <PatientSignIn />
    </Route>
    <Route path={`${routeMatch.path}/provider`}>
      <SelectProvider />
    </Route>
    <Route path={`${routeMatch.path}/pay`}>
      <Pay />
    </Route>
    <Route path={`${routeMatch.path}/failed`}>
      <CheckinFailed />
    </Route>
    <Route path={`${routeMatch.path}/checkin`}>
      <CheckinSuccess />
    </Route>
    <Route path={`${routeMatch.path}/waiting-room`}>
      <WaitingRoom />
    </Route>
    <Route path={`${routeMatch.path}/session`}>
      <SessionRoom userType="patient" />
    </Route>
    <Route path={`${routeMatch.path}/meeting`}>
      <GroupMeetingRoom userType="patient" />
    </Route>
    <Redirect from={`${routeMatch.path}`} to={`${routeMatch.path}/signin`} />
  </Switch>
);

const renderProviderPage = (component: JSX.Element) => (
  <ProviderModule>{component}</ProviderModule>
);

const ProviderRouter = () => (
  <Switch>
    <Route path={ProviderPath.OVERVIEW}>
      {renderProviderPage(<OverviewContainer />)}
    </Route>
    <Route path={ProviderPath.SCHEDULE}>
      {renderProviderPage(<ScheduleContainer />)}
    </Route>
    <Route path={ProviderPath.ANALYTICS}>
      {renderProviderPage(<Analytics />)}
    </Route>
    <Route path={ProviderPath.PATIENT}>{renderProviderPage(<Clients />)}</Route>
    <Route path={ProviderPath.MEETINGSFORM}>
      {renderProviderPage(<CreateMeetingForm />)}
    </Route>
    <Route path={ProviderPath.MEETINGS}>
      {renderProviderPage(<Meetings />)}
    </Route>
    <Route path={ProviderPath.PROVIDER}>
      {renderProviderPage(<Providers />)}
    </Route>
    <Route path={ProviderPath.UPDATE_PERMISSION}>
      {renderProviderPage(<PermissionsUpdate />)}
    </Route>
    <Route path={ProviderPath.PERMISSIONS}>
      {renderProviderPage(<Permissions />)}
    </Route>
    <Route path={ProviderPath.SETTINGS}>
      {renderProviderPage(<Settings />)}
    </Route>

    <Route path={ProviderPath.SESSION_DETAILS_REPORTS}>
      {renderProviderPage(<SessionDetailsReports />)}
    </Route>
    <Route path={ProviderPath.IN_PERSON_SESSION_DETAILS_REPORTS}>
      {renderProviderPage(<InPersonSessionDetailsReports />)}
    </Route>
    <Route path={ProviderPath.PAYMENTS_COLLECTED}>
      {renderProviderPage(<PaymentDetailsReports />)}
    </Route>
    <Route path={ProviderPath.REVENUE_BY_PROVIDER}>
      {renderProviderPage(<RevenueByProviderReports />)}
    </Route>
    <Route path={ProviderPath.APPOINTMENTS_BY_PROVIDER}>
      {renderProviderPage(<AppointmentsByProviderReports />)}
    </Route>

    <Route path={ProviderPath.REPORTS}>{renderProviderPage(<Reports />)}</Route>
    <Route path={ProviderPath.SESSION}>
      <SessionRoom userType="provider" />
    </Route>
    <Route path={ProviderPath.GROUP_MEETING}>
      <GroupMeetingRoom userType="provider" />
    </Route>
    <Redirect from={ProviderPath.ROOT} to={ProviderPath.OVERVIEW} />
  </Switch>
);

function App() {
  const uniqueId = getUserUniqueId();
  initializePendo({ accountUniqueId: uniqueId, visitorUniqueId: "" });

  return (
    <Router>
      <div>
        <Switch>
          <Route path="/patient" component={PatientRouter} />
          <Route path="/provider" component={ProviderRouter} />
          <Route path="/register">
            <RegisterScreen />
          </Route>
          <Route path="/">
            <WelcomeScreen />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
