import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  FiPhoneCall,
  FiCreditCard,
  FiCalendar,
  FiUsers,
  FiBarChart2,
} from "react-icons/fi";
import { device } from "../../assets/breakbpoints";
import { fontFamily } from "../../assets/fontFamily";
import { useScreenNameContext } from "../context/screenNameContext";
import { PermissionContext } from "../context/permissionContext";
import { Loader } from "../elements/Loader";
import { ProviderPath } from "../../types/provider";
import { Role } from "../../types/enum";

const StyledStatContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  & > div {
    flex-basis: 48.5%;
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const StyledStatTablet = styled.div`
  width: 85%;
  margin: 0 auto;
  display: flex;
  cursor: pointer

  & > div:first-child {
    display: grid;
    place-items: center;
    height: 60px;
    width: 20px;
    border-radius: 8.4762px;

    & > div {
      width: 40%;

      & img {
        width: 100%;
        height: auto;
      }
    }
  }

  & > div:nth-child(2) {
    font-family: ${fontFamily.body};
    margin-left: 30px;
    & p {
      font-size: 11px;
      color: #062942;
    }

    & h1 {
      font-weight: 20;
      font-size: 15px;
      color: #062942
    }
  }

  @media ${device.laptop} {
    & > div:first-child {
      height: 6vw;
      width: 2vw;
    }

    & > div:nth-child(2) {      

      & p {
        font-size: 12px;
        color: #062942;
      }
  
      & h1 {
        font-weight: 200;
        font-size: 16px;
        color: #062942
      }

    }
  }
`;

const StyledCard = styled.div`
  box-shadow: 0px 0px 1.73853px rgba(12, 26, 75, 0.24),
    0px 5.2156px 13.9083px -1.73853px rgba(50, 50, 71, 0.05);
  border-radius: 5px;
  background: #ffffff;
  width: 100%;
  padding-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
`;

const StyledReportCard = (props: {
  title: string;
  icon: any;
  description: string;
  tabIndex: number;
  onClick: any;
}) => (
  <StyledStatTablet
    role="button"
    tabIndex={props.tabIndex}
    onClick={props.onClick}
    onKeyDown={props.onClick}
  >
    <div>
      <div>{props.icon}</div>
    </div>
    <div>
      <h1>{props.title}</h1>
      <p>{props.description}</p>
    </div>
  </StyledStatTablet>
);

export const Reports = () => {
  const history = useHistory();
  const [loader, showLoader] = useState(true);
  const { permission } = useContext(PermissionContext) as any;
  const { setCurrentPageName } = useScreenNameContext() as any;

  if (!permission || permission.role === Role.PROVIDER) {
    history.push("/provider/overview", { permission });
  }

  useEffect(() => {
    setCurrentPageName("Reports");
    showLoader(false);
  });

  return (
    <>
      <h2>
        <span>Payments Reports</span>
      </h2>
      <StyledStatContainer>
        <div>
          <StyledCard>
            <StyledReportCard
              title="Copay Collected"
              icon={<FiCreditCard size={50} />}
              description="A summary of all copay you have collected over a period of time"
              onClick={() => {
                history.push(ProviderPath.PAYMENTS_COLLECTED, { permission });
              }}
              tabIndex={0}
            />
          </StyledCard>
        </div>
        <div>
          <StyledCard>
            <StyledReportCard
              title="Revenue by Provider"
              icon={<FiBarChart2 size={50} />}
              description="A breakdown of copay payments session for each of your provider"
              onClick={() => {
                history.push(ProviderPath.REVENUE_BY_PROVIDER, { permission });
              }}
              tabIndex={0}
            />
          </StyledCard>
        </div>
      </StyledStatContainer>

      <h2>
        <span>Teleconsult & In-Person Session Reports</span>
      </h2>
      <StyledStatContainer>
        <div>
          <StyledCard>
            <StyledReportCard
              title="Teleconsult Session Details"
              icon={<FiPhoneCall size={50} />}
              description="A detailed summary of all teleconsult sessions over a period of time"
              onClick={() => {
                history.push(ProviderPath.SESSION_DETAILS_REPORTS, {
                  permission,
                });
              }}
              tabIndex={0}
            />
          </StyledCard>
        </div>
        <div>
          <StyledCard>
            <StyledReportCard
              title="In-Person Session Details"
              icon={<FiUsers size={50} />}
              description="A detailed summary of all in-persons sessions over a period of time"
              onClick={() => {
                history.push(ProviderPath.IN_PERSON_SESSION_DETAILS_REPORTS, {
                  permission,
                });
              }}
              tabIndex={0}
            />
          </StyledCard>
        </div>
      </StyledStatContainer>

      <h2>
        <span>Scheduled Appointments Reports</span>
      </h2>
      <StyledStatContainer>
        <div>
          <StyledCard>
            <StyledReportCard
              title="Appointments By Provider"
              icon={<FiCalendar size={50} />}
              description="A report of scheduled appointments for each provider"
              onClick={() => {
                history.push(ProviderPath.APPOINTMENTS_BY_PROVIDER, {
                  permission,
                });
              }}
              tabIndex={0}
            />
          </StyledCard>
        </div>
      </StyledStatContainer>
      <Loader visible={loader} text="Loading Reports Dashboard... " />
    </>
  );
};
