import moment from "moment";
import {
  databaseRef,
  getCallableCreatePaymentFromRequestFunction,
} from "./firebase";
import { Payment } from "../types/payment";
import logger from "../utils/logger";
import { ApptValue } from "../types/appt-value";
import { PaymentProvider, PaymentStatus } from "../types/enum";
import { generateMedconHash } from "../utils/hash";
import { Provider } from "../types/provider";

export default class PaymentStore {
  path: string;

  constructor() {
    this.path = "payment";
  }

  async fetchPayment(id: string): Promise<Payment> {
    let payment = {} as Payment;
    try {
      const identifier = id;
      const paymentDoc = await databaseRef
        .child(`${this.path}/${identifier}`)
        .once("value");
      if (paymentDoc.exists()) {
        payment = paymentDoc.val() as Payment;
      } else {
        logger.warn(`Payment with Id ${identifier} not found`);
      }
    } catch (err) {
      logger.error(err);
    }
    return payment;
  }

  async createPaymentFromAppointment(
    appointment: ApptValue,
    provider: Provider
  ): Promise<Payment> {
    let payment = {} as Payment;
    try {
      const identifier = await generateMedconHash(appointment.id);
      const paymentRequest = {
        id: identifier,
        reference: appointment.id,
        description: "No Session Payment - Copay is 0.00",
        appointmentId: appointment.id,
        patientId: appointment.patientId,
        providerId: provider.id,
        amount: appointment.copayAmount,
        currency: appointment.currency,
        paymentProvider: PaymentProvider.AuthorizeNet,
        paymentDate: moment.utc().toISOString(),
        paymentDateTs: moment.utc().valueOf(),
        appointmentDate: appointment.appointmentDate,
        appointmentType: appointment.appointmentType,
        appointmentDateTs: appointment.appointmentDateTs,
        status: PaymentStatus.Completed,
        externalId: appointment.id,
        refTransId: appointment.id,
        patientName:
          appointment.clientPreferedName ||
          `${appointment.clientFirstName} ${appointment.clientLastName}`,
        providerName: provider.fullName,
        patientEmail: appointment.clientEmail,
        providerEmail: provider.email,
        paymentProviderAuthorizeResponse: "",
        paymentProviderCaptureResponse: "",
        location: appointment.location,
        requestedService: appointment.requestedService,
      } as Payment;
      logger.info(`Saving Payment with Id ${identifier}`);
      const createPaymentFromRequest = getCallableCreatePaymentFromRequestFunction();
      const response = await createPaymentFromRequest(paymentRequest);
      if (response) {
        payment = response.data as Payment;
        logger.info(`${this.path} with Id ${identifier} saved`);
      }
    } catch (err) {
      logger.error(err.message);
    }
    return payment;
  }

  async fetchPaymentByAppointmentId(
    appointment: ApptValue,
    provider: Provider,
    createDefault: boolean = false
  ): Promise<Payment> {
    let payment = {} as Payment;
    try {
      const identifier = appointment.id;
      const paymentDoc = await databaseRef
        .child(this.path)
        .orderByChild("appointmentId")
        .equalTo(identifier)
        .once("value");
      if (paymentDoc.exists()) {
        Object.values(paymentDoc.val()).forEach((paymentValue) => {
          payment = paymentValue as Payment;
        });
      } else {
        logger.warn(`Payment with Id ${identifier} not found`);
        if (createDefault) {
          payment = await this.createPaymentFromAppointment(
            appointment,
            provider
          );
        }
      }
    } catch (err) {
      logger.error(err);
    }
    return payment;
  }

  async fetchPayments(): Promise<Payment[]> {
    const payments = [] as Payment[];
    try {
      const paymentsDoc = await databaseRef
        .child(`${this.path}`)
        .orderByChild("paymentDateTs")
        .once("value");
      if (paymentsDoc.exists() && Object.keys(paymentsDoc.val()).length >= 1) {
        Object.values(paymentsDoc.val()).forEach((payment) => {
          payments.push(payment as Payment);
        });
      }
    } catch (err) {
      logger.error(err.message);
    }
    return payments;
  }

  async fetchPaymentsByDate(startDate: any, endDate: any): Promise<Payment[]> {
    const payments = [] as Payment[];
    try {
      const paymentsDoc = await databaseRef
        .child(`${this.path}`)
        .orderByChild("paymentDateTs")
        .startAt(startDate)
        .endAt(endDate)
        .once("value");
      if (paymentsDoc.exists() && Object.keys(paymentsDoc.val()).length >= 1) {
        Object.values(paymentsDoc.val()).forEach((payment) => {
          payments.push(payment as Payment);
        });
      }
    } catch (err) {
      logger.error(err.message);
    }
    return payments;
  }
}
