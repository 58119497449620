import { createHash } from "crypto";

// SHA-1, SHA-128, SHA-256, and SHA-512 hash functions.
export async function generateMedconHash(
  valueToHash: string,
  algorithmType: string = "sha256"
) {
  const hash = await createHash(algorithmType)
    .update(`medcon-${valueToHash}`, "utf8")
    .digest("hex");
  return hash;
}

export async function getMedconPatientId(email: string, dob: Date) {
  const dateStr = dob.toISOString().split("T")[0];
  return generateMedconHash(`${email}-${dateStr}`);
}

export function getUserUniqueId() {
  const uniqueId =
    Math.random().toString(36).substring(2) + new Date().getTime().toString(36);
  return uniqueId;
}

export function getUniqueId() {
  const uniqueId =
    Math.random().toString(10).substring(2) + new Date().getTime().toString(10);
  return uniqueId;
}

export function getReference() {
  const uniquereference = Math.floor(Math.random() * Date.now());
  const prefix = process.env.NODE_ENV !== "development" ? "T" : "P";
  return `${prefix}-${uniquereference}`;
}

export function formatWithCurrency(amount: number, currency: string = "USD") {
  const currencyFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  });
  const formattedAmount = currencyFormat.format(amount);
  return formattedAmount;
}
