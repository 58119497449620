import { validate } from "email-validator";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import medconLogo from "../../assets/images/medcon-logo.png";
import sideImage1 from "../../assets/images/sideImage1.png";
import { firebaseAuth, getCallableCaptchaFunction } from "../../store/firebase";
import PatientStore from "../../store/patient";
import { Provider } from "../../types/provider";
import { isDateValid } from "../../utils/date-helper";
import { getUserUniqueId } from "../../utils/hash";
import {
  initializePendo,
  initializeFullStory,
} from "../../utils/web-analytics";
import { BackButton } from "../elements/BackButton";
import { InputComponent } from "../elements/InputComponent";
import { Loader } from "../elements/Loader";
import { OnBoardingTopText } from "../elements/OnBoardingTopText";
import { PrimaryButtonComponent } from "../elements/PrimaryButtonComponent";
import { TextInput } from "../elements/TextInput";
import { Validation } from "../elements/Validation";
import { OnBoardingScaffold } from "../layout/OnBoardingScaffold";

declare const window: any;
declare const document: any;

export const PatientSignIn = () => {
  const history = useHistory();

  const [patientEmail, setPatientEmail] = useState("");
  const [patientDob, setPatientDob] = useState("");

  const [emailValidation, showEmailValidation] = useState(false);
  const [dobValidation, showDobValidation] = useState(false);
  const [loader, showLoader] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    script.id = "recaptcha";
    document.body.appendChild(script);
  }, []);

  const clearValidation = () => {
    showEmailValidation(false);
    showDobValidation(false);
    showLoader(true);
  };

  const handleClick = async () => {
    clearValidation();

    if (!patientEmail || !validate(patientEmail.trim())) {
      showEmailValidation(true);
      showLoader(false);
      return;
    }

    if (!patientDob || !isDateValid(moment(patientDob), "Day", "MM/DD/YYYY")) {
      showDobValidation(true);
      showLoader(false);
      return;
    }

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: "submit" })
        .then(async (token: string) => {
          // validate captcha token and get patient Information
          const validateToken = getCallableCaptchaFunction();
          const response = await validateToken({ token });
          const showError = () => {
            history.push(`/patient/failed`, {
              message: [
                "We couldn't find your appointment.",
                "If you have an appointment at this time, Please contact our support desk.",
              ].join(" "),
              allowBack: true,
            });
          };
          if (response && response.data) {
            const sanitizedEmail = patientEmail.trim().toLowerCase();
            const [pwd] = moment(patientDob).toISOString().split("T");
            firebaseAuth
              .signInWithEmailAndPassword(sanitizedEmail, pwd)
              .then(() => {
                const patientStore = new PatientStore();
                patientStore
                  .getPatientAndProviders(sanitizedEmail, patientDob)
                  .then(async (patientAndProviders: any | null) => {
                    if (patientAndProviders) {
                      const [patient, providers] = patientAndProviders;
                      if (providers && patient) {
                        const providersWithAppointments: any[] = [];
                        providers.forEach((provider: Provider) => {
                          if (provider.appointments) {
                            const providerAppointments: any[] = [];
                            Object.values(provider.appointments).forEach(
                              (value) => {
                                Object.values(value).forEach((data) => {
                                  if (
                                    !moment(data.appointmentDate)
                                      .add(1, "hours")
                                      .isBefore() &&
                                    data.clientEmail.toLowerCase() ===
                                      sanitizedEmail
                                  ) {
                                    providerAppointments.push(data);
                                  }
                                });
                              }
                            );

                            if (providerAppointments.length >= 1) {
                              providersWithAppointments.push(provider);
                            }
                          }
                        });

                        if (providersWithAppointments.length >= 1) {
                          const uniqueId = getUserUniqueId();
                          initializePendo({
                            accountUniqueId: uniqueId,
                            visitorUniqueId: patient!.id,
                          });

                          initializeFullStory({
                            visitorUniqueId: patient!.id,
                          });

                          history.push(`/patient/pay`, {
                            patient,
                            providers: providersWithAppointments,
                          });
                        } else {
                          showError();
                        }
                      } else {
                        showError();
                      }
                    } else {
                      showError();
                    }
                  });
              })
              .catch(() => {
                showError();
              });
          } else {
            showLoader(false);
          }
        });
    });
  };

  const validateDateValue = (e: any) => {
    const event = e || window.event;
    const charCode =
      typeof event.which === "undefined" ? event.keyCode : event.which;
    const charStr = String.fromCharCode(charCode);
    if (/\d/.test(charStr) || charStr === "/") {
      return true;
    }
    e.preventDefault();
    return false;
  };

  return (
    <OnBoardingScaffold
      topItem={
        <OnBoardingTopText
          prefixText="Please"
          logo={medconLogo}
          segment="Login"
          botMessage="Kindly enter your information to"
          newLine="access your scheduled appointment."
        />
      }
      sideImage={sideImage1}
    >
      <form
        onSubmit={async (e: any) => {
          e.preventDefault();
        }}
      >
        <InputComponent
          title="EMAIL"
          input={
            <TextInput
              onChange={(e: any) => {
                setPatientEmail(e.target.value);
              }}
              type="text"
              placeholder="email@example.com"
              onKeyUp={(e: any) => {
                if (e.keyCode === 13) {
                  document.getElementById("dobInput").focus();
                }
              }}
            />
          }
          validation={
            <Validation
              text="Please enter valid email address"
              visible={emailValidation}
            />
          }
        />
        <InputComponent
          title="DATE OF BIRTH (MM/DD/YYYY)"
          input={
            <TextInput
              type="text"
              placeholder="MM/DD/YYYY"
              onChange={(e: any) => {
                setPatientDob(e.target.value);
              }}
              maxLength={10}
              onKeyPress={(e: any) => {
                if (e && e.length === 10) {
                  e.preventDefault();
                  return false;
                }
                const isValid = validateDateValue(e);
                return isValid;
              }}
              onKeyUp={(e: any) => {
                if (e.keyCode === 13) {
                  handleClick();
                }
              }}
              id="dobInput"
            />
          }
          validation={
            <Validation
              text="Please enter a valid date of birth"
              visible={dobValidation}
            />
          }
        />
        <PrimaryButtonComponent onClick={handleClick} buttonText="Continue" />
        <BackButton />
      </form>

      <Loader visible={loader} text="Please wait ... " />
    </OnBoardingScaffold>
  );
};
