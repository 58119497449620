import { Badge, Popover } from "antd";
import React from "react";
import { FiBell } from "react-icons/fi";
import styled from "styled-components";
import { device } from "../../assets/breakbpoints";
import { theme } from "../../assets/theme";

const StyledPopover = styled(Popover)`
  border-radius: 10px;
`;

const StyledAlertAndButtondiv = styled.div<{ isProvider?: boolean }>`
  display: ${(props) => (props.isProvider ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media ${device.laptop} {
    display: flex;
  }
`;

const StyledAlertDiv = styled.div`
  font-size: 18px;
  display: flex;
  align-items: center;
`;

const StyledFiBell = styled(FiBell)`
  color: ${theme.mutedColor};
`;

export const Alert = (props: {
  isProvider?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => (
  <StyledAlertAndButtondiv
    isProvider={props.isProvider}
    onClick={props.onClick}
  >
    <StyledAlertDiv>
      <StyledFiBell size={30} />
      <Badge
        count={23}
        style={{
          padding: "0px 11px 0px 11px",
          backgroundColor: props.isProvider ? "#DBEDE3" : "#FFE6E4",
          color: props.isProvider ? "#27AE60" : "#F16063",
          fontWeight: 700,
          borderColor: props.isProvider ? "#DBEDE3" : "#FFE6E4",
        }}
      />
    </StyledAlertDiv>
  </StyledAlertAndButtondiv>
);

export const AlertWithPopOver = (props: {
  notificationCount?: number;
  popContent: JSX.Element;
  popTitle: JSX.Element;
  isProvider?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => (
  <StyledPopover
    placement="bottomRight"
    content={props.popContent}
    title={props.popTitle}
  >
    <StyledAlertAndButtondiv
      isProvider={props.isProvider}
      onClick={props.onClick}
    >
      <StyledAlertDiv>
        <StyledFiBell size={30} />
        {Boolean(props.notificationCount) && (
          <Badge
            count={props.notificationCount}
            style={{
              padding: "0px 11px 0px 11px",
              backgroundColor: props.isProvider ? "#DBEDE3" : "#FFE6E4",
              color: props.isProvider ? "#27AE60" : "#F16063",
              fontWeight: 700,
              borderColor: props.isProvider ? "#DBEDE3" : "#FFE6E4",
            }}
          />
        )}
      </StyledAlertDiv>
    </StyledAlertAndButtondiv>
  </StyledPopover>
);
