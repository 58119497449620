import styled from "styled-components";
import { FiMenu } from "react-icons/fi";
import { device } from "../../assets/breakbpoints";
import { fontFamily } from "../../assets/fontFamily";
import { theme } from "../../assets/theme";

export const StyledNameDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;

  & img {
    width: 12px;
    height: auto;
    object-fit: contain;
    display: inline;
  }

  & p {
    margin-bottom: 0px;
    margin-left: 10px;
    margin-right: 3vw;

    font-family: ${fontFamily.body};
    font-size: 14px;
    font-weight: 400;
    color: #2d3743;
  }

  @media ${device.laptop} {
    & img {
      width: 6%;
    }
    & p {
      font-size: 1vw;
    }
  }

  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    font-size: 14.22px;

    & div:nth-child(1),
    div:nth-child(2) {
      margin-bottom: 10px;
      height: 5px;
      width: 90%;
      font-size: 14.22px;
      padding-top: 7px;
      border-radius: 11.1546px;
    }

    & div:nth-child(3) {
      height: 5px;
      width: 90%;
      font-size: 14.22px;
      padding-top: 7px;
      border-radius: 11.1546px;
    }
  }
`;

export const StyledFiMenu = styled(FiMenu)`
  color: ${theme.mutedColor};
  font-size: 15px;

  @media ${device.laptop} {
    font-size: 1.2vw;
  }
`;

export const StyledFooter = styled.div<{ otherWidth: number }>`
  display: flex;
  align-items: center;
  margin-left: calc(6vw + ${(props) => props.otherWidth}px);
  margin-top: 5px;
  & p {
    font-size: ${fontFamily.body};
    font-size: 12px;
    font-weight: 400;
    margin: 0px;
    margin-left: 15px;
    color: #2d3743;
  }

  @media ${device.laptop} {
    & p {
      font-size: 0.81vw;
    }
  }
`;

export const StyledEventButton = styled.div<{ width?: number }>`
  border: 1.11546px solid #cedce2;
  border-radius: 11.1546px;
  display: flex;
  width: ${(props) => props.width}%;
  align-items: center;
  font-size: 14.22px;
  background-color: #fff;
  padding: 10px 15px;
  margin-right: 10px;
  cursor: pointer;

  & p {
    margin-bottom: 0px;
    margin-left: 9px;
    font-family: ${fontFamily.body};
    font-weight: 500;
    color: #5d6d74;
  }

  @media
  only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px)  {
    font-size: 14.22px;

    & img{
      margin-top: 3.5px;
    }
`;

export const StyledActionButtons = styled.div`
  display: flex;
  margin-left: auto;
  margin-bottom: 20px;
`;

export const StyledAlert = styled.div`
  width: 60%;
`;

export const StyledDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  padding: 20px;
  width: 50%;
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
  color: #333333;
`;

export const StyledCenteredDiv = styled.div`
  align-items: center;
  justify-content: center;
  width: 50%;
  text-align: center;
  font-size: 16px;
`;
