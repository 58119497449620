import React, { useEffect, useState } from "react";
import { AiFillCheckCircle, AiFillNotification } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { device } from "../../assets/breakbpoints";
import { fontFamily } from "../../assets/fontFamily";
import { theme } from "../../assets/theme";
import { databaseRef } from "../../store/firebase";
import {
  WaitingRoom,
  WaitingRoomParticipant,
  WaitingRoomStatus,
} from "../../types/session";
import { AlertWithPopOver } from "../elements/Alert";
import { WaitlistActionButtons } from "../elements/WaitlistActionButtons";
import { ProviderPath } from "../../types/provider";

const StyledSingleNotification = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 10px 20px;

  & > div:first-child {
    margin-right: 40px;
  }
`;

const StyledAppointmentDetail = styled.div`
  flex-basis: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  & div {
    display: flex;
    height: fit-content;
    align-items: center;

    & p {
      margin: 0px;
      color: ${theme.mutedColor};
      font-family: ${fontFamily.body};
      font-weight: 400;
      font-size: 10px;
      margin-left: 5px;
    }

    & > div {
      margin-left: auto;
      color: #da9344;
      & p {
        color: #da9344;
      }
    }
  }

  & > p {
    margin: 0px;
    font-family: ${fontFamily.heading};
    font-size: 14px;
    font-weight: 500;
  }

  @media ${device.laptop} {
    margin: 0px;
    flex-basis: auto;

    & div {
      & p {
        font-size: 0.75vw;
      }
    }

    & > p {
      font-size: 0.97vw;
    }
  }
`;

const StyledPopTitle = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 20px;

  & h4 {
    margin: 0px;
    font-size: 18px;
    font-family: ${fontFamily.heading};
    font-weight: 500;
  }
`;

const StyledAiFillCheckCircle = styled(AiFillCheckCircle)`
  color: #219653;
  margin-right: 17px;
  font-size: 30px;
`;

const StyledAiFillNotification = styled(AiFillNotification)`
  color: #219653;
  margin-right: 17px;
  font-size: 30px;
`;

export const AppointmentDetail = ({
  title,
  providerName,
  waitingSince,
}: {
  title: string;
  providerName: string;
  waitingSince: string;
}) => (
  <StyledAppointmentDetail>
    <p>{title}</p>
    <div>
      <p>{providerName}</p>

      <div>
        <p>{waitingSince}</p>
      </div>
    </div>
  </StyledAppointmentDetail>
);

export const WaitlistNotifications = ({
  providerId,
  paymentId,
  fullName,
}: {
  providerId: string;
  paymentId?: string;
  fullName: string;
}) => {
  const history = useHistory();
  const [pendingNotifications, setPendingNotifications] = useState(
    [] as WaitingRoom[]
  );

  async function updateWaitingRoom(
    waitingRoomEntry: WaitingRoom,
    status: WaitingRoomStatus
  ) {
    const waitListPath = databaseRef.child(
      `waiting_room/${waitingRoomEntry.providerId}/${waitingRoomEntry.path}`
    );
    await waitListPath.update({ status });

    // update session status
    if (
      waitingRoomEntry.waitingRoomParticipant === WaitingRoomParticipant.SINGLE
    ) {
      const sessionPath = databaseRef.child(
        `session/${waitingRoomEntry.paymentId}`
      );
      await sessionPath.update({ sessionStatus: status });

      if (
        status === WaitingRoomStatus.ACCEPTED &&
        paymentId !== waitingRoomEntry.paymentId
      ) {
        history.push(ProviderPath.SESSION, {
          sessionDetails: {
            paymentId: waitingRoomEntry.paymentId,
            participant: {
              name: fullName,
              id: providerId,
              isProvider: true,
            },
          },
        });
      }
    }
  }

  useEffect(() => {
    const updateWaitroomNotification = (snapshot: any) => {
      const updatedWaitingRoom = snapshot.val() as WaitingRoom;
      setPendingNotifications((prevNotifications) => {
        const allPendingNotifications = [
          ...prevNotifications.filter((p) => p.id !== updatedWaitingRoom.id),
          updatedWaitingRoom,
        ].filter((n) => n.status === WaitingRoomStatus.PENDING);
        return allPendingNotifications;
      });
    };

    const waitListPath = databaseRef.child(`waiting_room/${providerId}`);
    waitListPath.on("child_changed", updateWaitroomNotification);
    waitListPath.on("child_added", updateWaitroomNotification);
  }, [providerId]);

  const popContent = pendingNotifications.length ? (
    <>
      {pendingNotifications.map((waitRoomNotification) => {
        const formattedDateTime = `Waiting for ${moment(
          waitRoomNotification.startTime
        ).fromNow(true)}`;
        return (
          <StyledSingleNotification>
            <AppointmentDetail
              title={waitRoomNotification.patientName}
              providerName=""
              waitingSince={`${formattedDateTime}`}
            />
            <WaitlistActionButtons
              info={waitRoomNotification}
              acceptWait={async () => {
                await updateWaitingRoom(
                  waitRoomNotification,
                  WaitingRoomStatus.ACCEPTED
                );
              }}
              rejectWait={async () => {
                await updateWaitingRoom(
                  waitRoomNotification,
                  WaitingRoomStatus.REJECTED
                );
              }}
            />
          </StyledSingleNotification>
        );
      })}
    </>
  ) : (
    <></>
  );

  const popTitle = pendingNotifications.length ? (
    <StyledPopTitle>
      <StyledAiFillNotification />
      <h4>{`You have ${pendingNotifications.length} client${
        pendingNotifications.length > 1 ? "s" : ""
      } on the waitlist`}</h4>
    </StyledPopTitle>
  ) : (
    <StyledPopTitle>
      <StyledAiFillCheckCircle />
      <h4>All caught up with your notifications!</h4>
    </StyledPopTitle>
  );
  return (
    <AlertWithPopOver
      popContent={popContent}
      notificationCount={pendingNotifications.length}
      isProvider
      popTitle={popTitle}
    />
  );
};
