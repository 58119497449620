import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import medconLogo from "../../assets/images/medcon-logo.png";
import sideImage5 from "../../assets/images/sideImage5.png";
import { OnBoardingTopText } from "../elements/OnBoardingTopText";
import { PrimaryButtonComponent } from "../elements/PrimaryButtonComponent";
import { OnBoardingScaffold } from "../layout/OnBoardingScaffold";
import CardAuthorizeRequest from "../../types/card-authorize-request";
import { firebaseAuth } from "../../store/firebase";

declare const window: any;

export const CheckinSuccess = () => {
  const history = useHistory();
  const browserLocation = useLocation();

  const gotoWaitingRoom = (paymentId: string) => {
    history.push("/patient/waiting-room", { paymentId });
  };

  const [cardAuthorizeRequest] = useState(
    (browserLocation.state as any)?.cardAuthorizeRequest as CardAuthorizeRequest
  );

  if (!cardAuthorizeRequest) {
    history.push("/patient/signin");
    return <></>;
  }

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(async (user: any) => {
      if (!user) {
        history.push("/");
      }
    });
  });

  useEffect(() => {
    if (!cardAuthorizeRequest) {
      history.push(`/patient/signin`);
    }
  }, [cardAuthorizeRequest]);

  // prevent going back after payment is successful
  window.addEventListener("popstate", () => {
    history.go(1);
  });

  return (
    <OnBoardingScaffold
      topItem={
        <OnBoardingTopText
          prefixText="Your Session with"
          logo={medconLogo}
          segment={cardAuthorizeRequest.providerName}
          continousSeg="has been booked."
          checkinMargin="30"
          botMessage="You can enter the waiting room"
          newLine="by clicking the button below."
        />
      }
      sideImage={sideImage5}
    >
      <PrimaryButtonComponent
        onClick={() => {
          gotoWaitingRoom(cardAuthorizeRequest.id || "");
        }}
        buttonText="Go to Waiting Room"
      />
    </OnBoardingScaffold>
  );
};
