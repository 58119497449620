import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/analytics";
import "firebase/functions";
import { PaymentCaptureType, Role } from "../types/enum";
import logger from "../utils/logger";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABSE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
};

// Initialize Firebase and stop the firebase initialisation happening multiple times
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  // TODO Enable analytics before shipping to Production
  // firebase.analytics();
}

export const databaseRef = firebase
  .database()
  .ref(process.env.REACT_APP_FB_PROJECT_ID);

export function getCallablePaymentFunction(
  paymentCaptureType: PaymentCaptureType
) {
  let paymentFunction = firebase
    .functions()
    .httpsCallable("authorizeCreditCard");
  if (paymentCaptureType === PaymentCaptureType.AuthorizeAndCapture) {
    paymentFunction = firebase
      .functions()
      .httpsCallable("authorizeAndChargeCreditCard");
  }
  return paymentFunction;
}

export function getCallableCaptchaFunction() {
  const callableFunction = firebase
    .functions()
    .httpsCallable("validateRecaptchaToken");
  return callableFunction;
}

export function getCallableCreatePaymentFromRequestFunction() {
  const callableFunction = firebase
    .functions()
    .httpsCallable("createPaymentFromRequest");
  return callableFunction;
}

export function getGenerateMeetingTokenFunction() {
  const callableFunction = firebase
    .functions()
    .httpsCallable("generateMeetingToken");
  return callableFunction;
}

export function getFetchBlogFunction() {
  return firebase.functions().httpsCallable("fetchBlogContents");
}

export async function authenticate() {
  const firebaseAuth = firebase.auth();
  let authResult = {} as any;
  try {
    // await firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
    googleAuthProvider.addScope("profile");
    googleAuthProvider.addScope("email");
    authResult = await firebaseAuth.signInWithPopup(googleAuthProvider);
  } catch (err) {
    logger.error(err);
  }
  return authResult;
}

export function getBackendUsersFunction() {
  return firebase.functions().httpsCallable("getBackendUsers");
}

export function getUserPermissionFunction() {
  return firebase.functions().httpsCallable("getUserPermission");
}

export function updateUserPermissionFunction() {
  return firebase.functions().httpsCallable("updateUserPermission");
}

export function registerUserFunction() {
  return firebase.functions().httpsCallable("registerUser");
}

export function getMeetingAuthenticationTokenFunction() {
  return firebase.functions().httpsCallable("getMeetingAuthenticationToken");
}

export function executeImportProviderAppointmentsFunction() {
  return firebase
    .functions()
    .httpsCallable("executeImportProviderAppointments");
}

export const firebaseAuth = firebase.auth();

export const validateAccess = async (
  userPermission: any,
  email: string,
  pageHistory: any,
  allowedRoles: Role[]
) => {
  let permission = userPermission;
  // get permission for logged in user
  if (!userPermission && email) {
    const getUserPermission = getUserPermissionFunction();
    const response = await getUserPermission({ email });
    permission = response.data;
  } else if (!userPermission && !email) {
    pageHistory.push("/");
  }

  // redirect to dashboard if user does not have access
  if (permission && !allowedRoles.includes(permission.role)) {
    pageHistory.push("/provider/overview", { userPermission });
  } else if (!permission) {
    pageHistory.push("/");
  }

  return permission;
};

export default { authenticate, firebase, databaseRef, firebaseAuth };
