import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { BackButton } from "../atoms/BackButton";
import { ReportContainer } from "./ReportContainer";
import { AppointmentsByProviderReportsBody } from "./AppointmentsByProviderReportsBody";
import {
  StyledGroupHead,
  ReportGroupHeader,
  ReportGroupFooter,
} from "./ReportAssets";
import { PermissionContext } from "../../context/permissionContext";
import { Loader } from "../../elements/Loader";
import ProviderStore from "../../../store/provider";
import { formatWithCurrency, getUniqueId } from "../../../utils/hash";
import { AppointmentType, Role } from "../../../types/enum";

export const AppointmentsByProviderReports = () => {
  const [loader, showLoader] = useState(true);
  const dateFormat = "MM-DD-YYYY";
  const [reportStartDate, setReportStartDate] = useState(
    moment().startOf("month")
  );
  const [reportEndDate, setReportEndDate] = useState(moment().endOf("month"));
  const reportTitle = "Appointments by Provider";
  const reportFileName = "appointments-by-provider-report.csv";
  const groupByFilter = ["Provider", "Date"];
  const [appointmentData, setAppointmentData] = useState([] as any);
  const [reportBody, setReportBody] = useState([] as any);
  const reportHeaders = [
    "Client",
    "Service",
    "Location",
    "Appointment Type",
    "Copay",
    "Date & Time",
  ];

  const history = useHistory();
  const { permission } = useContext(PermissionContext) as any;

  if (!permission || permission.role === Role.PROVIDER) {
    history.push("/provider/overview", { permission });
  }

  const processReportData = (orderedPayments: any[]) => {
    const headers = reportHeaders.map((item, index) => {
      const key = `thkey-${index}-${getUniqueId()}`;
      return <th key={key}>{item}</th>;
    });

    const groupedPayments = _.groupBy(orderedPayments, "provider");
    const paymentsElements: any[] = [];
    let grandTotal = 0;
    Object.keys(groupedPayments).forEach((provider: any) => {
      // Group header, by provider
      paymentsElements.push(
        <tr>
          <td colSpan={7}>
            <ReportGroupHeader>{provider}</ReportGroupHeader>
          </td>
        </tr>
      );

      // Group row header
      paymentsElements.push(<StyledGroupHead>{headers}</StyledGroupHead>);

      const groupedPayment = groupedPayments[provider];

      let total = 0;

      Object.values(groupedPayment).forEach((payment: any) => {
        const key = `curr-data-${getUniqueId()}`;
        paymentsElements.push(
          <AppointmentsByProviderReportsBody key={key} data={payment} />
        );
        total += 1;
      });

      grandTotal += total;

      paymentsElements.push(
        <tr>
          <td colSpan={3}>
            <ReportGroupFooter> Total Appointments</ReportGroupFooter>
          </td>
          <td>
            <ReportGroupFooter>{total}</ReportGroupFooter>
          </td>
        </tr>
      );
    });
    paymentsElements.push(
      <tr>
        <td colSpan={3}>
          <ReportGroupFooter> Grand Total Appointments</ReportGroupFooter>
        </td>
        <td>
          <ReportGroupFooter>{grandTotal}</ReportGroupFooter>
        </td>
      </tr>
    );

    setReportBody(paymentsElements);
    showLoader(false);
  };

  const fetchPaymentsByDate = (startDate: any, endDate: any) => {
    showLoader(true);
    const providerStore = new ProviderStore();
    providerStore
      .getAppointments(startDate.valueOf(), endDate.valueOf())
      .then((appointments: any[]) => {
        const parsedAppointments = [] as any[];
        Object.values(appointments).forEach((appointment: any) => {
          const appointmentDate = moment(appointment.appointmentDate);
          parsedAppointments.push({
            client: `${appointment.clientFirstName} ${appointment.clientLastName}`,
            service: appointment.requestedService,
            location: appointment.location,
            formattedDate: appointmentDate.format("MMMM, D yyyy"),
            timeStamp: appointmentDate.format("HH:mm"),
            appointmentType: Object.keys(AppointmentType)[
              Object.values(AppointmentType).indexOf(
                appointment.appointmentType
              )
            ],
            copay: formatWithCurrency(
              appointment.copayAmount,
              appointment.currency
            ),
            provider: appointment.provider,
            date: appointmentDate.format("YYYY-MM-DD"), // format date for easy sorting
          });
        });

        // group data by date and order desc and group by date
        const orderedAppointments = _.orderBy(
          parsedAppointments,
          ["date", "timeStamp"],
          "asc"
        );
        setAppointmentData(orderedAppointments);
        processReportData(orderedAppointments);
      });
  };

  useEffect(() => {
    fetchPaymentsByDate(reportStartDate, reportEndDate);
  }, []);

  const filterByDate = (
    startDate: any,
    endDate: any,
    processDataHandler: any
  ) => {
    setReportStartDate(startDate);
    setReportEndDate(endDate);
    showLoader(true);
    if (startDate && endDate) {
      fetchPaymentsByDate(startDate, endDate);
    } else {
      processDataHandler(appointmentData);
    }
  };

  return (
    <>
      <BackButton />
      <ReportContainer
        reportTitle={reportTitle}
        dateFormat={dateFormat}
        showStartDate
        showEndDate
        showGroupBy={false}
        showApply
        showPrint
        showExport
        groupByList={groupByFilter}
        reportBody={reportBody}
        data={appointmentData}
        reportFileName={reportFileName}
        filterHandler={filterByDate}
        dataHandler={processReportData}
        reportStartDate={reportStartDate}
        reportEndDate={reportEndDate}
      />

      <Loader visible={loader} text={`Loading ${reportTitle} ...`} />
    </>
  );
};
