import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "antd/dist/antd.css";
import "./assets/styles/index.css";
import reportWebVitals from "./utils/report-web-vitals";
import { sendToGoogleAnalytics } from "./utils/web-analytics";
import "react-big-calendar/lib/css/react-big-calendar.css";

declare const document: any;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// https://bit.ly/CRA-vitals
reportWebVitals(sendToGoogleAnalytics);
