import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getGenerateMeetingTokenFunction } from "../../../store/firebase";
import logger from "../../../utils/logger";
import { Loader } from "../../elements/Loader";

declare const JitsiMeetExternalAPI: any;

const ConferenceCallWindow = styled.div`
  height: 80vh;
`;

function ConferenceCall({
  participantId,
  displayName,
  onMeetingEnd,
  meetingId,
  email,
  duration,
}: {
  roomName: string;
  participantId: string;
  displayName: string;
  meetingId: string;
  email: string;
  duration: number;
  isProvider?: boolean;
  onMeetingEnd?: () => void;
}) {
  const [hasJoinedCall, setHasjoinedCall] = useState(false);

  useEffect(() => {
    const setupConferenceCall = async (userHasJoinedCall: boolean) => {
      if (userHasJoinedCall) {
        return;
      }
      try {
        const tokenFunction = getGenerateMeetingTokenFunction();
        const requestData = {
          roomName: meetingId,
          name: displayName,
          email,
          id: participantId,
          expiryTimeStamp: duration,
        };
        const tokenResponse: any = await tokenFunction(requestData);
        if (!tokenResponse) {
          logger.error("No token response for video call");
        }
        const jwt: string = tokenResponse.data;
        const domain = process.env.REACT_APP_MEDCON_SERVER_URL;
        const options = {
          roomName: meetingId,
          parentNode: document.querySelector("#conference-call-window"),
          noSSL: false,
          height: "100%",
          width: "100%",
          jwt,
          userInfo: {
            participantId,
            email: email || `${participantId}@medcon.live`,
            displayName,
          },
          // https://github.com/jitsi/jitsi-meet/blob/master/config.js
          configOverwrite: {
            enableWelcomePage: false,
            prejoinPageEnabled: false,
            maxFps: 25,
            constraints: {
              video: {
                frameRate: {
                  max: 25,
                },
                height: {
                  ideal: 720,
                  max: 720,
                  min: 240,
                },
              },
            },
          },
          // https://github.com/jitsi/jitsi-meet/blob/master/interface_config.js
          interfaceConfigOverwrite: {
            OPTIMAL_BROWSERS: ["chrome", "chromium", "firefox", "safari"],
            MOBILE_DOWNLOAD_LINK_ANDROID: "",
            MOBILE_DOWNLOAD_LINK_F_DROID: "",
            MOBILE_DOWNLOAD_LINK_IOS: "",
            SUPPORT_URL: "htps://support.medcon.live",
            NATIVE_APP_NAME: "Medcon Secure Telemedicine System",
            PROVIDER_NAME: "Medcon",
            DEFAULT_LOCAL_DISPLAY_NAME: "me",
            DEFAULT_REMOTE_DISPLAY_NAME: "Participant",
            MOBILE_APP_PROMO: false,
            DEFAULT_BACKGROUND: "#F6A851",
            DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
            DISPLAY_WELCOME_FOOTER: false,
            HIDE_DEEP_LINKING_LOGO: true,
            SHOW_CHROME_EXTENSION_BANNER: false,
            CLOSE_PAGE_GUEST_HINT: false,
            HIDE_INVITE_MORE_HEADER: true,
            TOOLBAR_BUTTONS: [
              "microphone",
              "camera",
              "desktop",
              "fullscreen",
              "fodeviceselection",
              "hangup",
              "profile",
              "chat",
              "etherpad",
              "raisehand",
              "filmstrip",
              "tileview",
              "mute-everyone",
              "videobackgroundblur",
            ],
          },
          onload: () => {
            setHasjoinedCall(true);
          },
        };
        const api = new JitsiMeetExternalAPI(domain, options);
        // set the video screen subject to empty to avoid displaying meeting id
        api.executeCommand("subject", "");
        api.on("readyToClose", () => {
          api.executeCommand("hangup");
          api.dispose();
          if (onMeetingEnd) {
            onMeetingEnd();
          }
        });
      } catch (err) {
        logger.error(err);
      }
    };
    setupConferenceCall(hasJoinedCall);
  }, [hasJoinedCall]);
  return (
    <div className="conference-call-container">
      <ConferenceCallWindow id="conference-call-window" />
      <Loader
        visible={!hasJoinedCall}
        text="Loading Secured Teleconference Session ... "
      />
    </div>
  );
}

export default ConferenceCall;
