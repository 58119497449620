import React from "react";
import styled from "styled-components";
import { fontFamily } from "../../../assets/fontFamily";

const StyledInputButton = styled.span`
  background-color: #fff;
  display: inline-flex;
  align-items: center;
  border: 1.11546px solid #cedce2;
  border-radius: 11.1546px;
  width: fit-content;
  padding: 10px;
  font-family: ${fontFamily.body};
  font-size: 14.22px;
  background-color: #fff;
  padding: 10px 15px;
  margin-right: 10px;
  cursor: pointer;
  margin-bottom: 7px;

  & span {
    margin-bottom: 0px;
    margin-left: 9px;
    font-family: ${fontFamily.body};
    font-weight: 500;
    color: #5d6d74;
  }
  
  @media 
  only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px)  {
    font-size: 14.22px;

    & img{
      margin-top: 3.5px;
    }
`;

export const StyledButton = (props: {
  text?: string;
  icon?: any;
  onClick?: any;
}) => (
  <StyledInputButton onClick={props.onClick}>
    {props.icon}
    <span>{props.text}</span>
  </StyledInputButton>
);
