import React, { useEffect, useContext, useState } from "react";
import { Col, DatePicker, Input, Select, Form, Alert } from "antd";
import MultiSelect from "react-multi-select-component";
import styled from "styled-components";
import { FiCopy } from "react-icons/fi";
import { Loader } from "../elements/Loader";
import { device } from "../../assets/breakbpoints";
import { fontFamily } from "../../assets/fontFamily";
import MeetingStore from "../../store/meeting";
import {
  TimeZone,
  MeetingStatus,
  VisibilityStatus,
  MeetingType,
} from "../../types/enum";
import { Meeting } from "../../types/meeting";
import { useScreenNameContext } from "../context/screenNameContext";
import { ApptButtonComponent } from "../elements/PrimaryButtonComponent";
import { BackButton } from "./atoms/BackButton";
import { getGenerateMeetingTokenFunction } from "../../store/firebase";
import { getUniqueId } from "../../utils/hash";
import { StyledButton } from "./atoms/StyledButton";
import { PermissionContext } from "../context/permissionContext";
import { Provider } from "../../types/provider";
import ProviderStore from "../../store/provider";

const StyledCreateMeetingForm = styled.div`
  background-color: #fff;
  border-radius: 16px;
  padding: 51px;
  margin-top: 30px;

  & h1 {
    color: #131523;
    font-size: 16px;
    font-family: ${fontFamily.Inter};
    font-weight: 700;
  }

  & > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  @media ${device.laptop} {
    & h1 {
      font-size: 1.2vw;
    }
  }
`;

export const CreateMeetingForm = () => {
  const { setCurrentPageName } = useScreenNameContext() as any;
  setCurrentPageName("Meeting");
  const meetingStore = new MeetingStore();
  const [loader, showLoader] = useState(true);
  const [alertVisible, setAlertVisible] = useState(false);
  const [timeZone, setTimeZone] = useState(TimeZone.EST);
  const [meetingType, setMeetingType] = useState(MeetingType.INTERNAL);
  const [date, setDate] = useState({} as any[]);
  const [topic, setTopic] = useState("");
  const [description, setDescription] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [link, setLink] = useState("");
  const [inviteeOptions, setInviteeOptions] = useState({} as any[]);

  const [invitees, setInvitees] = useState([]);
  const { RangePicker } = DatePicker;
  const { TextArea } = Input;
  const { permission } = useContext(PermissionContext) as any;

  useEffect(() => {
    setCurrentPageName("Create Meeting");
    if (permission) {
      const providerStore = new ProviderStore();
      providerStore.fetchProviders().then((providers: Provider[]) => {
        const options = [] as any[];

        Object.values(providers).forEach((provider: Provider) => {
          options.push({
            label: provider.fullName,
            value: provider.id,
            disabled: permission.id === provider.id,
          });
        });
        setInviteeOptions(options);
        showLoader(false);
      });
    }
  }, [permission]);

  function SaveMeeting() {
    if (date && date.length === 2 && topic && description && timeZone) {
      showLoader(true);
      const tokenFunction = getGenerateMeetingTokenFunction();
      const id = getUniqueId();
      const requestData = {
        roomName: id,
        expiryTimeStamp: date[1].valueOf(),
      };
      tokenFunction(requestData).then((tokenResponse) => {
        if (tokenResponse) {
          const format = "YYYY-MM-DDTHH:mm:ss.SSSZ";
          const jwt = tokenResponse.data;
          const meetingLink = `${process.env.REACT_APP_DOMAIN}/patient/waiting-room?m=${id}&ct=${jwt}`;
          const meeting: Meeting = {
            startTime: date[0].format(format),
            endTime: date[1].format(format),
            startTimeTs: date[0].valueOf(),
            endTimeTs: date[1].valueOf(),
            topic,
            description,
            link: meetingLink,
            timeZone,
            id,
            providerId: permission.id,
            visibilityStatus: VisibilityStatus.Active,
            status: MeetingStatus.STARTED,
            participants: [],
            callToken: jwt,
            meetingType,
            invitees,
            host: permission.id,
          };

          meetingStore.saveMeeting(meeting).then((response: boolean) => {
            setDescription("");
            setTopic("");
            setAlertMessage("Meeting has been successfully scheduled.");
            showLoader(false);
            setAlertVisible(response);
          });

          setLink(meetingLink);
        } else {
          showLoader(false);
        }
      });
    }
  }

  return (
    <>
      <Col xs={{ span: 24 }} lg={{ span: 20 }}>
        <BackButton />
        {alertVisible ? (
          <>
            <br />
            <Alert
              message={alertMessage}
              type="success"
              showIcon
              closable
              action={
                <StyledButton
                  icon={<FiCopy />}
                  text="Copy Link"
                  onClick={() => {
                    const textarea = document.createElement("textarea");
                    document.body.appendChild(textarea);
                    textarea.value = link;
                    textarea.select();
                    document.execCommand("copy");
                    document.body.removeChild(textarea);
                    setAlertMessage(
                      "Meeting link has been copied to clipboard."
                    );
                  }}
                />
              }
              onClose={() => {
                setAlertVisible(false);
              }}
            />
          </>
        ) : (
          ""
        )}

        <StyledCreateMeetingForm>
          <h1>Meeting Information</h1>
          <br />

          <Form labelCol={{ span: 9 }} wrapperCol={{ span: 15 }}>
            <Form.Item label="Topic" style={{ float: "left", width: "50%" }}>
              <Input
                type="text"
                value={topic}
                onChange={(e: any) => setTopic(e.target.value)}
              />
            </Form.Item>

            <Form.Item label="Date" style={{ right: "left", width: "50%" }}>
              <RangePicker showTime onChange={(e: any) => setDate(e)} />
            </Form.Item>

            <div style={{ clear: "both" }} />
            <Form.Item
              label="Description"
              style={{ float: "left", width: "50%" }}
            >
              <TextArea
                value={description}
                onChange={(e: any) => setDescription(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Timezone"
              style={{ float: "right", width: "50%" }}
            >
              <Select value={timeZone} onChange={(e: any) => setTimeZone(e)}>
                {Object.values(TimeZone).map((value) => (
                  <Select.Option value={value}>{value}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Meeting Type"
              style={{ float: "right", width: "50%" }}
            >
              <Select
                value={meetingType}
                onChange={(e: any) => setMeetingType(e)}
              >
                {Object.values(MeetingType).map((value) => (
                  <Select.Option value={value}>{value}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Invitees"
              style={{ float: "right", width: "50%" }}
            >
              <MultiSelect
                options={inviteeOptions}
                value={invitees}
                onChange={setInvitees}
                labelledBy="Select Invitee"
              />
            </Form.Item>
          </Form>
          <div style={{ textAlign: "center" }}>
            <ApptButtonComponent buttonText="Schedule" onClick={SaveMeeting} />
          </div>
        </StyledCreateMeetingForm>
      </Col>
      <Loader visible={loader} text="..." />
    </>
  );
};
