import React, { useEffect, useState } from "react";

function CountdownTimer({ targetTime }: { targetTime: Date }) {
  const calculateTimeLeft = () => {
    const difference = +targetTime - +new Date();
    let timeLeft: { [key: string]: number } = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents: JSX.Element[] = [];

  Object.keys(timeLeft).forEach((interval, idx) => {
    if (!timeLeft[interval]) {
      return;
    }
    const key = `key-${idx}`;
    timerComponents.push(
      <span key={`span-${key}`}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });
  return timerComponents.length ? <>{timerComponents}</> : null;
}

export default CountdownTimer;
