import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ProviderStore from "../../store/provider";
import { Provider } from "../../types/provider";
import { useScreenNameContext } from "../context/screenNameContext";
import { PermissionContext } from "../context/permissionContext";
import { TableMini } from "./atoms/TableMini";
import { Loader } from "../elements/Loader";
import { Role } from "../../types/enum";

const title = ["Name", "email", "contact number", "total appointments"];
const csvHeaders = [
  { label: "Name", key: "text" },
  { label: "Email", key: "field1" },
  { label: "Contact Number", key: "field2" },
  { label: "total appointments", key: "field3" },
];

export const Providers = () => {
  const history = useHistory();
  const [loader, showLoader] = useState(true);
  const [providersData, setProvidersData] = useState([] as any);
  const { permission } = useContext(PermissionContext) as any;
  const { setCurrentPageName } = useScreenNameContext() as any;

  if (!permission || permission.role === Role.PROVIDER) {
    history.push("/provider/overview", { permission });
  }

  useEffect(() => {
    setCurrentPageName("Therapists");
    if (permission) {
      const providerStore = new ProviderStore();
      providerStore.fetchProviders().then((providers: Provider[]) => {
        const formattedProviders = [] as any[];
        Object.values(providers).forEach((provider: Provider) => {
          formattedProviders.push({
            text: provider.fullName,
            field1: provider.email,
            field2: provider.contactNumber,
            field3: provider.appointments
              ? Object.keys(provider.appointments).length
              : 0,
          });
        });

        setProvidersData(formattedProviders);
        showLoader(false);
      });
    }
  }, [permission]);

  return (
    <>
      <TableMini
        sessionName="Therapists information from intake system"
        tableTitle={title}
        tableName="All Therapists"
        showLocationFilter={false}
        showDateFilter={false}
        data={providersData}
        csvHeaders={csvHeaders}
        isActive
        showSearch
        showExportButton
      />
      <Loader visible={loader} text="Loading ... " />
    </>
  );
};
