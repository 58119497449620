import { Alert, Col } from "antd";
import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FiDownload, FiMenu, FiEdit, FiDownloadCloud } from "react-icons/fi";
import { FcGoogle } from "react-icons/fc";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import queryString from "query-string";
import moment from "moment";
import styled from "styled-components";
import { device } from "../../../assets/breakbpoints";
import { fontFamily } from "../../../assets/fontFamily";
import { theme } from "../../../assets/theme";
import { useScreenNameContext } from "../../context/screenNameContext";
import { PermissionContext } from "../../context/permissionContext";
import { TimeIcon } from "../atoms/TimeIcon";
import { Loader } from "../../elements/Loader";
import { ScheduleBarComponentScaffold } from "../atoms/ScheduleBarComponentScaffold";
import { CreateAppointmentForm } from "../appointment/CreateAppointmentForm";
import { CreateAppointmentFormEmpty } from "../appointment/CreateAppointmentFormEmpty";
import { ScheduleCalendar } from "./ScheduleCalendar";
import { ScheduleEmpty } from "./ScheduleEmpty";
import { ApptValue } from "../../../types/appt-value";
import { Role } from "../../../types/enum";
import { Appointment } from "../../../types/appointment";
import {
  firebaseAuth,
  executeImportProviderAppointmentsFunction,
} from "../../../store/firebase";
import ProviderStore from "../../../store/provider";
import { generateMedconHash, getUniqueId } from "../../../utils/hash";
import { BackButton } from "../atoms/BackButton";

const ics = require("ics");

const StyledSceduleBar = styled.div`
  background-color: #fff;
  border-radius: 13.3855px;
  width: 100%;
  padding: 20px;
  padding-bottom: 30px;
  margin-bottom: 10px;

  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    & div {
      display: block;
      align-items: left;
      justify-content: flex-start;
      border-radius: 16px;
      padding: 27px 5px 27px 24px;
      margin: auto;
      box-shadow: 0px 0px 4px #f0f3f5, 0px -8px 4px rgba(241, 244, 246, 0.06);
    }

    & p {
      margin-left: 20px;
      float: left;
      margin-bottom: 0px;
      margin-left: 9px;
      justify-content: right;
      font-family: ${fontFamily.body};
      font-weight: 500;
      color: #5d6d74;
      font-size: 14.22px;
    }
  }
`;

const StyledUpperDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const StyledTimeDiv = styled.div<{ ref: any }>`
  display: flex;
  align-items: center;

  & p {
    margin: 0px;
    margin-left: 10px;
    font-family: ${fontFamily.heading};
    font-size: 15.62px;
    font-weight: lighter;

    & span {
      font-weight: 700;
      margin-right: 8px;
    }
  }

  @media ${device.laptop} {
    & p {
      font-size: 1.1vw;
    }
  }

  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    & div {
      display: block;
      justify-content: center;
      border-radius: 16px;
      padding: 10px;
      margin: auto;
      box-shadow: 0px 0px 4px #f0f3f5, 0px -8px 4px rgba(241, 244, 246, 0.06);
      width: 20%;
    }

    & p {
      margin-top: 4px;
      text-align: center;
      margin-bottom: 0px;
      font-family: ${fontFamily.body};
      font-weight: 500;
      color: #5d6d74;
      width: 100%;
    }
  }
`;

const StyledProviderName = styled.div`
  margin-left: 6vw;
  & h2 {
    color: ${theme.secondaryColor};
    font-family: ${fontFamily.body};
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  & p {
    margin: 0px;
  }

  @media ${device.laptop} {
    margin-left: 5vw;
    & h2 {
      font-size: 1.1vw;
    }
  }

  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    & h2 {
      text-align: center;
      width: 100%;
      height: 0px;
    }
  }
`;

const StyledNameDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;

  & img {
    width: 12px;
    height: auto;
    object-fit: contain;
    display: inline;
  }

  & p {
    margin-bottom: 0px;
    margin-left: 10px;
    margin-right: 3vw;

    font-family: ${fontFamily.body};
    font-size: 14px;
    font-weight: 400;
    color: #2d3743;
  }

  @media ${device.laptop} {
    & img {
      width: 6%;
    }
    & p {
      font-size: 1vw;
    }
  }

  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    font-size: 14.22px;

    & div:nth-child(1),
    div:nth-child(2) {
      margin-bottom: 10px;
      height: 5px;
      width: 90%;
      font-size: 14.22px;
      padding-top: 7px;
      border-radius: 11.1546px;
    }

    & div:nth-child(3) {
      height: 5px;
      width: 90%;
      font-size: 14.22px;
      padding-top: 7px;
      border-radius: 11.1546px;
    }
  }
`;

const StyledFiMenu = styled(FiMenu)`
  color: ${theme.mutedColor};
  font-size: 15px;

  @media ${device.laptop} {
    font-size: 1.2vw;
  }
`;

const StyledFooter = styled.div<{ otherWidth: number }>`
  display: flex;
  align-items: center;
  margin-left: calc(6vw + ${(props) => props.otherWidth}px);
  margin-top: 5px;
  & p {
    font-size: ${fontFamily.body};
    font-size: 12px;
    font-weight: 400;
    margin: 0px;
    margin-left: 15px;
    color: #2d3743;
  }

  @media ${device.laptop} {
    & p {
      font-size: 0.81vw;
    }
  }
`;

const StyledEventButton = styled.div<{ width?: number }>`
  border: 1.11546px solid #cedce2;
  border-radius: 11.1546px;
  display: flex;
  width: ${(props) => props.width}%;
  align-items: center;
  font-size: 14.22px;
  background-color: #fff;
  padding: 10px 15px;
  margin-right: 10px;
  cursor: pointer;

  & p {
    margin-bottom: 0px;
    margin-left: 9px;
    font-family: ${fontFamily.body};
    font-weight: 500;
    color: #5d6d74;
  }

  @media
  only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px)  {
    font-size: 14.22px;

    & img{
      margin-top: 3.5px;
    }
`;

const StyledActionButtons = styled.div`
  display: flex;
  margin-left: auto;
  margin-bottom: 20px;
`;

const ScheduleBar = (props: { appointment: ApptValue }) => {
  const [timeDivWidth, setTimeDivWidth] = useState(0);
  const timeRef = useRef();
  const history = useHistory();
  const [providerId, setProviderId] = useState("");
  const [providerEmail, setProviderEmail] = useState("");

  const appointmentStartDate = moment(props.appointment.appointmentDate);
  const appointmentEndDate = moment(props.appointment.appointmentEndDate);

  useEffect(() => {
    const userEmail = firebaseAuth.currentUser?.email!;
    setProviderEmail(userEmail);
    generateMedconHash(userEmail).then((hashedId: string) => {
      setProviderId(hashedId);
    });

    setTimeDivWidth((timeRef?.current as any)?.getBoundingClientRect().width);
    window.addEventListener("resize", () => {
      setTimeDivWidth((timeRef?.current as any)?.getBoundingClientRect().width);
    });

    return () =>
      window.removeEventListener("resize", () => {
        setTimeDivWidth(
          (timeRef?.current as any)?.getBoundingClientRect().width
        );
      });
  }, []);

  return (
    <StyledSceduleBar>
      <StyledUpperDiv>
        <StyledTimeDiv ref={timeRef}>
          <TimeIcon large borderColor="#ECEEF5" color="#8083A3" />
          <p>
            <span>{appointmentStartDate.format("HH:mm")}</span> &mdash; {"  "}
            {appointmentEndDate.format("HH:mm")}
          </p>
        </StyledTimeDiv>

        <StyledProviderName>
          <h2>
            {props.appointment.requestedService} ({props.appointment.location})
            : {props.appointment.clientFirstName}{" "}
            {props.appointment.clientLastName}
          </h2>
        </StyledProviderName>
      </StyledUpperDiv>

      <StyledFooter otherWidth={timeDivWidth}>
        <StyledFiMenu style={{ float: "left" }} />
        <p>
          {props.appointment.clientEmail}, {props.appointment.clientPhoneNo}
        </p>

        <StyledNameDiv>
          <StyledEventButton
            width={20}
            onClick={() => {
              history.push(
                `/provider/schedule/create-appointment?appointmentId=${props.appointment.id}&patientId=${props.appointment.patientId}&providerId=${providerId}`
              );
            }}
          >
            <FiEdit style={{ float: "left" }} size={20} />
            <p>Update</p>
          </StyledEventButton>

          <StyledEventButton
            width={28}
            onClick={() => {
              const description = `${props.appointment.requestedService} Session with ${props.appointment.clientFirstName} ${props.appointment.clientLastName}`;
              const date = moment(props.appointment.appointmentDate);
              const event = {
                start: [
                  date.format("YYYY"),
                  date.format("MM"),
                  date.format("DD"),
                  date.format("HH"),
                  date.format("mm"),
                ],
                duration: { hours: 1, minutes: 0 },
                title: props.appointment.requestedService,
                description,
                location: props.appointment.location,
                categories: [
                  props.appointment.requestedService,
                  props.appointment.appointmentType,
                ],
                status: "CONFIRMED",
                busyStatus: "BUSY",
                organizer: {
                  name: props.appointment.providerName || providerEmail,
                },
                attendees: [
                  {
                    name: props.appointment.providerName,
                    email: providerEmail,
                    rsvp: true,
                    partstat: "ACCEPTED",
                    role: "REQ-PARTICIPANT",
                  },
                  {
                    name: `${props.appointment.clientFirstName} ${props.appointment.clientLastName}`,
                    email: props.appointment.clientEmail,
                    role: "REQ-PARTICIPANT",
                  },
                ],
              };

              ics.createEvent(event, (error: any, value: any) => {
                if (value) {
                  window.open(
                    `data:text/calendar;charset=utf8,${escape(value)}`
                  );
                }
              });
            }}
          >
            <FiDownload style={{ float: "left" }} size={20} />
            <p>Download ICS</p>
          </StyledEventButton>
          <StyledEventButton
            width={38}
            onClick={() => {
              const description = `${props.appointment.requestedService} Session with ${props.appointment.clientFirstName} ${props.appointment.clientLastName}`;
              const appointmentStart = moment(
                props.appointment.appointmentDate
              ).format("YYYYMMDDTHHmmssZ");
              const appointmentEnd = moment(props.appointment.appointmentDate)
                .add(1, "hours")
                .format("YYYYMMDDTHHmmssZ");
              const url = `https://www.google.com/calendar/render?action=TEMPLATE&text=Client+Appointment&details=${description}&location=${props.appointment.location}&dates=${appointmentStart}%2F${appointmentEnd}`;
              window.open(url, "_blank");
            }}
          >
            <FcGoogle size={15} />
            <p>Add to Google Calendar</p>
          </StyledEventButton>
        </StyledNameDiv>
      </StyledFooter>
    </StyledSceduleBar>
  );
};

const ScheduleBarComponent = (props: {
  day: string;
  otherDate: string;
  displaySwitchButton?: boolean;
  hideSwitch?: boolean;
  handleSwitch?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children?: any;
  data: any;
}) => {
  const appointments = props.data[0] as ApptValue[];
  const scheduleBars: any[] = [];
  appointments.forEach((appointment) => {
    const key = `sb-${getUniqueId()}`;
    scheduleBars.push(<ScheduleBar key={key} appointment={appointment} />);
  });

  return (
    <>
      {scheduleBars.length > 0 ? (
        <ScheduleBarComponentScaffold
          onClick={props.onClick}
          day={props.day}
          handleSwitch={props.handleSwitch}
          otherDate={props.otherDate}
          displaySwitchButton={Boolean(props.displaySwitchButton)}
        >
          {scheduleBars}
        </ScheduleBarComponentScaffold>
      ) : (
        <></>
      )}
    </>
  );
};

export const Schedule = () => {
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const browserLocation = useLocation();
  const { setCurrentPageName } = useScreenNameContext() as any;
  const { permission } = useContext(PermissionContext) as any;
  const [loader, showLoader] = useState(true);
  const [showImport, setShowImport] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [appointments, setAppointments] = useState([] as ApptValue[]);
  const [formattedAppointments, setFormattedAppointments] = useState(
    [] as any[]
  );
  const parsedQueryParams = queryString.parse(browserLocation.search || "");
  const [clientId] = useState((parsedQueryParams.clientId as string) || "");
  const [clientName] = useState((parsedQueryParams.clientName as string) || "");

  useEffect(() => {
    setCurrentPageName("Schedule");
    if (permission && permission.email) {
      if (
        permission.role === Role.ADMIN ||
        permission.role === Role.OWNER
      ) {
        // do not show import button for now
        setShowImport(false);
      }
      generateMedconHash(permission.email).then((identifier: string) => {
        const providerStore = new ProviderStore();
        const appointmentsData = [] as ApptValue[];

        providerStore
          .fetchProviderAppointments(identifier)
          .then((responses: any) => {
            Object.values(responses).forEach((response) => {
              const appointment = response as Appointment;
              Object.keys(appointment).forEach((apptKey) => {
                const appointmentValue = appointment[apptKey] as ApptValue;
                if (
                  clientId === "" ||
                  appointmentValue.patientId === clientId
                ) {
                  appointmentsData.push(appointmentValue);
                }
              });
            });

            const groupedAppointments = _.mapValues(
              _.groupBy(appointmentsData, (appointment) => {
                const date = moment(appointment.appointmentDate).format(
                  "MMMM D, yyyy"
                );
                return date;
              }),
              (group) => _.orderBy(group, ["appointmentDateTs"], ["asc"])
            );

            const formattedAppointmentsData = _.map(
              groupedAppointments,
              (groupedAppointment, appointmentDay) => {
                const item = {
                  dateTs: moment(appointmentDay).valueOf(),
                  date: {
                    day: moment(appointmentDay).format("dddd"),
                    otherDate: moment(appointmentDay).format("MMMM D, yyyy"),
                  },
                  schedule: [groupedAppointment],
                };
                return item;
              }
            );

            const orderedAppointments = _.orderBy(
              formattedAppointmentsData,
              ["dateTs"],
              ["asc"]
            );

            setAppointments(appointmentsData);
            setFormattedAppointments(orderedAppointments);
            showLoader(false);

            if (
              clientId === "" &&
              orderedAppointments &&
              orderedAppointments.length === 0
            ) {
              history.push(`${url}overview`);
            }
          });
      });
    }
  }, [permission]);

  const handleCreateAppointment = () => {
    history.push(`${url}/create-appointment`);
  };

  const handleSwitch = (appointmentsData: ApptValue[]) => {
    history.push(`${url}/calender`, { appointments: appointmentsData });
  };

  const scheduleList = formattedAppointments.map((item, index) => {
    const key = `k-${index}-${getUniqueId()}`;
    if (index === 0) {
      return (
        <ScheduleBarComponent
          key={key}
          day={item.date.day}
          otherDate={item.date.otherDate}
          data={item.schedule}
          displaySwitchButton={clientId === ""}
          onClick={handleCreateAppointment}
          handleSwitch={() => {
            handleSwitch(appointments);
          }}
        />
      );
    }
    return (
      <ScheduleBarComponent
        key={key}
        day={item.date.day}
        otherDate={item.date.otherDate}
        data={item.schedule}
      />
    );
  });

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Col xs={{ span: 24 }} style={{ marginTop: "30px" }}>
          <p>
            Your schedule is synced daily from the intake system. To edit the
            day/time of your appointment on the &quot;Clinician
            availability&quot; page on the Intake system -{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://dps-intake-project.web.app/availability"
            >
              Click here.
            </a>{" "}
            To make a temporary change to the appointment time, use the
            &quot;update&quot; button below.
          </p>

          {clientId !== "" ? (
            <>
              <BackButton />

              <h2>
                {" "}
                {formattedAppointments.length === 0 ? "No" : ""} Scheduled
                Appointments for Client - <b>{clientName}</b>
              </h2>
              <br />
            </>
          ) : (
            ""
          )}

          {alertVisible ? (
            <>
              <Alert
                message={alertMessage}
                type="info"
                showIcon
                closable
                onClose={() => {
                  setAlertVisible(false);
                }}
              />
              <br />
              <br />
            </>
          ) : (
            ""
          )}
          {showImport ? (
            <>
              <StyledActionButtons>
                <StyledEventButton
                  onClick={() => {
                    showLoader(true);
                    const executeImportProviderAppointments = executeImportProviderAppointmentsFunction();
                    executeImportProviderAppointments()
                      .then(() => {
                        setAlertMessage(
                          "Appointments import job triggered successfully, please refresh the Schedule page to see the imported appointments"
                        );
                        setAlertVisible(true);
                        showLoader(false);
                      })
                      .catch(() => {
                        setAlertMessage(
                          "Appointments import could not be triggered, please ensure that the data in the Intake Google sheet is in the right format"
                        );
                        setAlertVisible(true);
                        showLoader(false);
                      });
                  }}
                >
                  <FiDownloadCloud size={20} />
                  <p>Import Appointments</p>
                </StyledEventButton>
              </StyledActionButtons>
            </>
          ) : (
            ""
          )}
          <>{scheduleList}</>
        </Col>
        <Loader visible={loader} text="Fetching Appointments ... " />
      </Route>
      <Route path={`${path}/create-appointment`}>
        <CreateAppointmentForm />
      </Route>
      <Route path={`${path}/create-appointment-empty`}>
        <CreateAppointmentFormEmpty />
      </Route>
      <Route path={`${path}/calender`}>
        <ScheduleCalendar />
      </Route>
      <Route path={`${path}/overview`}>
        <ScheduleEmpty />
      </Route>
    </Switch>
  );
};
