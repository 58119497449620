import { Col } from "antd";
import React from "react";
import { FiCheckSquare, FiClock, FiLayout } from "react-icons/fi";
import { IoMdCalendar, IoMdPeople } from "react-icons/io";
import { MdPeopleOutline } from "react-icons/md";
import styled from "styled-components";
import { device } from "../../../assets/breakbpoints";
import { fontFamily } from "../../../assets/fontFamily";
import { EmptyWaitingListIcon } from "../../../assets/svgs/EmptyWaitingListIcon";
import { theme } from "../../../assets/theme";
import { Provider } from "../../../types/provider";
import { CreateAppointment } from "../atoms/CreateAppointment";

const StyledButton = styled.button`
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: ${theme.primaryColor};
  outline: none;
  border: none;
  font-size: 12px;
  color: ${theme.black};
  cursor: pointer;

  & p {
    margin: 0px;
    margin-left: 5px;
  }

  @media ${device.laptop} {
    font-size: 0.95vw;
    padding: 8px 17px;
  }
`;

const StyledTopStatPane = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 16px;
  padding: 27px 5px 27px 24px;
  margin-top: 20px;
  box-shadow: 0px 0px 4px #f0f3f5, 0px -8px 4px rgba(241, 244, 246, 0.06);
  width: 100%;

  @media ${device.laptop} {
    width: 24%;
  }
`;

const StyledImageDiv = styled.div`
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  padding: 12px;

  @media ${device.laptop} {
    font-size: 1.5vw;
  }
`;

const StyledStatNumberDiv = styled.div`
  margin-left: 20px;
  height: 100%;
  & p {
    font-family: ${fontFamily.body};
    font-weight: 600;
    margin: 0px;
    &:first-child {
      color: ${theme.mutedColor};
      font-size: 12px;
      line-height: 17px;
      margin-bottom: 5px;
    }

    &:nth-child(2) {
      font-size: 20px;
      font-weight: 600;
      font-family: ${fontFamily.body};
      margin-bottom: 0px;
      line-height: 17px;
    }
  }
`;

const StyledStatSummaryDiv = styled(Col)`
  /* width: 100%; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;

  @media ${device.laptop} {
    justify-content: space-between;
  }
`;

export const StyledMainStatBody = styled(Col)`
  margin-top: 20px;

  & > div {
    flex-basis: 100%;

    &:first-child {
      margin-bottom: 20px;
    }
  }

  @media ${device.laptop} {
    display: flex;
    justify-content: space-between;
    & > div {
      flex-basis: 48%;

      &:first-child {
        margin-bottom: 0px;
      }
    }
  }
`;
const StyledMainStat = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 16px;
  padding: 22px;
  margin-bottom: 20px;

  & > div:first-child {
    display: flex;
    justify-content: space-between;

    & div:first-child {
      & p {
        margin: 0px;
        font-family: ${fontFamily.body};
        font-weight: 400;
        font-size: 12px;
        color: ${theme.mutedColor};
      }

      & h2 {
        margin: 0px;
        font-family: ${fontFamily.heading};
        font-weight: 500;
        font-size: 16px;
      }
    }

    & div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.secondaryColor};
      height: fit-content;

      & p {
        margin: 0px;
        margin-left: 10px;
      }
    }
  }

  @media ${device.laptop} {
  }
`;

const StyledExtraLargeButton = styled(StyledButton)`
  border-radius: 12px;
  font-size: 14px;
  color: #fff;
  width: 100%;
  justify-content: center;
  background-image: linear-gradient(
    to right,
    ${theme.secondaryColor},
    ${theme.secondaryColor_lighter}
  );
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;

  @media ${device.tablet} {
    font-size: 20px;
    & p {
      margin-left: 20px;
    }
  }
`;

const StyledIoMdPeople = styled(IoMdPeople)`
  font-size: 24px;
  transform: rotateY(180deg);

  @media ${device.laptop} {
    font-size: 1.7vw;
  }
`;

const StyledEmptyWaitingList = styled.div`
  display: grid;
  place-items: center;
  padding: 40px 0 70px 0;
  & h1 {
    font-family: ${fontFamily.heading};
    font-weight: 600;
    font-size: 20px;
  }

  & p {
    font-family: ${fontFamily.body};
    font-weight: 400;
    font-size: 12px;
    color: #7a8593;
  }

  @media ${device.laptop} {
    & h1 {
      font-size: 1.4vw;
    }

    & p {
      font-size: 0.8vw;
    }
  }
`;

const StyledEmptyUpcomingEvents = styled.div`
  width: 100%;
  padding-top: 70px;
  margin-bottom: 40px;
  display: grid;
  place-items: center;
`;

export const TitledCard = (props: {
  children: any;
  showCalender?: boolean;
  title: string;
  titleFooter: string;
  visible: boolean;
}) => (
  <StyledMainStat style={{ display: props.visible ? "block" : "none" }}>
    <div>
      <div>
        <h2>{props.title}</h2>
        <p>{props.titleFooter}</p>
      </div>
      {props.showCalender && (
        <div>
          <IoMdCalendar />
          <p>View schedule</p>
        </div>
      )}
    </div>
    {props.children}
  </StyledMainStat>
);

export const EmptyWaitingList = (props: { visible: boolean }) => (
  <TitledCard
    visible={props.visible}
    title="Wait List"
    titleFooter="Keep an eye on Clients' waitlist"
  >
    <StyledEmptyWaitingList>
      <EmptyWaitingListIcon />
      <h1>No one on the wait list</h1>
      <p>
        Clients on the wait list will be displayed here.
        <br /> You can accept or decline their request
      </p>
    </StyledEmptyWaitingList>
  </TitledCard>
);

export const EmptyUpcomingEvents = (props: {
  visible: boolean;
  provider?: Provider;
}) => (
  <TitledCard
    visible={props.visible}
    title="Upcoming Appointments"
    titleFooter="You currently have no scheduled appointments"
  >
    <StyledEmptyUpcomingEvents>
      <CreateAppointment />
    </StyledEmptyUpcomingEvents>
  </TitledCard>
);

export const QuickAction = (props: {
  visible: boolean;
  createAppt: () => void;
}) => (
  <TitledCard
    visible={props.visible}
    title="Quick actions"
    titleFooter="Schedule a Client's appointment"
  >
    <StyledExtraLargeButton onClick={props.createAppt}>
      <StyledIoMdPeople />
      <p>Add New Appointment</p>
    </StyledExtraLargeButton>
  </TitledCard>
);

export const TopStatPane = (props: {
  backgroundColor: string;
  title: string;
  figure: string;
  icon: JSX.Element;
  iconColor: string;
}) => (
  <StyledTopStatPane>
    <StyledImageDiv
      style={{ backgroundColor: props.backgroundColor, color: props.iconColor }}
    >
      {/* <img src={props.icon} alt=''/> */}
      {props.icon}
    </StyledImageDiv>
    <StyledStatNumberDiv>
      <p>{props.title}</p>
      <p>{props.figure}</p>
    </StyledStatNumberDiv>
  </StyledTopStatPane>
);

export const StatSummarySegment = (props: {
  apptCnt: number;
  upcomingCnt: number;
  sessionCnt: number;
  AvgTime: string;
}) => (
  <StyledStatSummaryDiv sm={{ span: 24 }}>
    <TopStatPane
      backgroundColor="#E1E8FF"
      icon={<FiLayout />}
      title="Total appointments this week"
      figure={props.apptCnt ? props.apptCnt.toString() : "0"}
      iconColor="#4C6FFF"
    />
    <TopStatPane
      backgroundColor="#FFEEF1"
      icon={<MdPeopleOutline />}
      title="Upcoming appointments this week"
      figure={props.upcomingCnt ? props.upcomingCnt.toString() : "0"}
      iconColor="#FF6984"
    />
    <TopStatPane
      backgroundColor="#FFEDE3"
      icon={<FiCheckSquare />}
      title="Completed appointments this week"
      figure={props.sessionCnt ? props.sessionCnt.toString() : "0"}
      iconColor="#F7936F"
    />
    <TopStatPane
      backgroundColor="#DEFFEE"
      icon={<FiClock />}
      title="Average wait time"
      figure={props.AvgTime.concat(" Min")}
      iconColor="#66CB9F"
    />
  </StyledStatSummaryDiv>
);
