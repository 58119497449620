import { getCLS, getFID, getLCP } from "web-vitals";

declare const window: any;
declare const pendo: any;
declare const FS: any;

export function sendToGoogleAnalytics({
  id,
  name,
  delta,
}: {
  id: any;
  name: string;
  delta: number;
}) {
  // https://developers.google.com/analytics/devguides/collection/gtagjs
  window.gtag("event", name, {
    event_category: "Web Vitals",
    // Google Analytics metrics must be integers, so the value is rounded.
    // For CLS the value is first multiplied by 1000 for greater precision
    // (note: increase the multiplier for greater precision if needed).
    value: Math.round(name === "CLS" ? delta * 1000 : delta),
    // The `id` value will be unique to the current page load. When sending
    // multiple values from the same page (e.g. for CLS), Google Analytics can
    // compute a total by grouping on this ID (note: requires `eventLabel` to
    // be a dimension in your report).
    event_label: id,
    // Use a non-interaction event to avoid affecting bounce rate.
    non_interaction: true,
  });
}

// https://support.pendo.io/hc/en-us/articles/360046272771-Developer-s-Guide-To-Installing-Pendo
export function initializePendo({
  accountUniqueId,
  visitorUniqueId = "",
}: {
  accountUniqueId: string;
  visitorUniqueId: string;
}) {
  pendo.initialize({
    visitor: {
      id: visitorUniqueId, // Required if user is logged in
      // email:        // Recommended if using Pendo Feedback, or NPS Email
      // full_name:    // Recommended if using Pendo Feedback
      // role:         // Optional

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },

    account: {
      id: accountUniqueId, // Highly recommended
      // name:         // Optional
      // is_paying:    // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      // planLevel:    // Optional
      // planPrice:    // Optional
      // creationDate: // Optional

      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
    },
    excludeAllText: true,
    disableGuides: true,
    excludeTitle: true,
  });
}

export function initializeFullStory({
  visitorUniqueId = "",
}: {
  visitorUniqueId: string;
}) {
  FS.identify(visitorUniqueId, {
    visitorUniqueId,
    // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-Recording-custom-user-data
    reviewsWritten_int: 14,
  });
}

getCLS(sendToGoogleAnalytics);
getFID(sendToGoogleAnalytics);
getLCP(sendToGoogleAnalytics);
