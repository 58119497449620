import { v4 } from "uuid";
import { ApptValue } from "../types/appt-value";
import { Location, MeetingStatus, RequestedServiceType } from "../types/enum";
import { Meeting } from "../types/meeting";
import { Payment } from "../types/payment";
import {
  SessionStatus,
  WaitingRoom,
  WaitingRoomParticipant,
  WaitingRoomSource,
  WaitingRoomStatus,
} from "../types/session";
import { generateMedconHash } from "../utils/hash";
import { databaseRef } from "./firebase";
import SessionStore from "./session";

const { IN_APP } = WaitingRoomSource;
export default class WaitingRoomStore {
  payment?: Payment;

  meeting?: Meeting;

  path: string;

  constructor({ payment, meeting }: { payment?: Payment; meeting?: Meeting }) {
    this.payment = payment;
    this.meeting = meeting;
    this.path = "waiting_room";
  }

  private async createWaitingRoomForPayment(
    payment: Payment,
    checkinSource: WaitingRoomSource
  ) {
    const sessionDetails = await new SessionStore(payment).createOrJoinSession(
      false
    );
    if (!sessionDetails) {
      return {
        result: false,
        message:
          "We are unable to connect you to your session. No session available.",
      };
    }
    const { session, name, id } = sessionDetails;
    if (session.sessionStatus === SessionStatus.ENDED) {
      return {
        result: false,
        message: "Your provider has ended your session.",
      };
    }
    const appointment = await this.getAppointment(); // TODO: Add appointment time to payment object?.
    if (!appointment) {
      return {
        result: false,
        message:
          "We are unable to connect you to your session. No appointments available.",
      };
    }
    const waitingRoomPath = await generateMedconHash(
      `${payment.providerId}/${payment.appointmentId}/${payment.id}/${payment.patientId}`
    );
    const waitingRoom: WaitingRoom = {
      id: waitingRoomPath,
      path: waitingRoomPath,
      paymentId: payment.id,
      providerId: session.providerId,
      patientId: id,
      startTime: session.waitroomStartTime,
      startTimeTs: session.waitroomStartTimeTs,
      patientName: name,
      location: session.location,
      status: WaitingRoomStatus.PENDING,
      appointmentId: appointment.id,
      requestedService: appointment.requestedService,
      checkinSource,
      waitingRoomParticipant: WaitingRoomParticipant.SINGLE,
    };
    await this.saveWaitingRoom(waitingRoom);
    return { result: true, message: "", appointment, waitingRoom };
  }

  private async createWaitingRoomForMeeting(
    meeting: Meeting,
    checkinSource: WaitingRoomSource
  ) {
    if (meeting.status === MeetingStatus.ENDED) {
      return {
        result: false,
        message: "Your meeting has ended.",
      };
    }

    const guestId = v4(); // Because we dont want them sharing waiting room.
    const waitingRoomPath = await generateMedconHash(
      `${meeting.providerId}/${meeting.id}/${guestId}`
    );
    const waitingRoom: WaitingRoom = {
      id: waitingRoomPath,
      path: waitingRoomPath,
      paymentId: meeting.id,
      providerId: meeting.providerId,
      patientId: guestId,
      startTime: new Date(meeting.startTime),
      startTimeTs: meeting.startTimeTs,
      patientName: `Guest`,
      location: Location.Remote,
      status: WaitingRoomStatus.PENDING,
      appointmentId: meeting.id,
      requestedService: RequestedServiceType.TeleTherapy,
      checkinSource,
      waitingRoomParticipant: WaitingRoomParticipant.GROUP,
    };
    await this.saveWaitingRoom(waitingRoom);
    return { result: true, message: "", waitingRoom };
  }

  async enterWaitingRoom(
    checkinSource: WaitingRoomSource = IN_APP
  ): Promise<{
    result: boolean;
    message: string;
    appointment?: ApptValue;
    waitingRoom?: WaitingRoom;
  }> {
    if (this.payment) {
      return this.createWaitingRoomForPayment(this.payment, checkinSource);
    }

    if (this.meeting) {
      return this.createWaitingRoomForMeeting(this.meeting, checkinSource);
    }

    return {
      result: false,
      message: "",
    };
  }

  private async getAppointment() {
    if (!this.payment) {
      return null;
    }
    const appointmentDoc = await databaseRef
      .child(
        `provider/${this.payment.providerId}/appointments/${this.payment.patientId}/${this.payment.appointmentId}`
      )
      .get();
    if (appointmentDoc.exists()) {
      return appointmentDoc.val() as ApptValue;
    }
    return null;
  }

  private async saveWaitingRoom(waiting: WaitingRoom) {
    await databaseRef
      .child(`${this.path}/${waiting.providerId}/${waiting.path}`)
      .update(waiting);
  }
}
