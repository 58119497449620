import { Appointment } from "./appointment";

export interface Provider {
  id: string; // provider id
  email: string;
  fullName: string;
  contactNumber: string;
  appointments: Appointment;
}

export enum ProviderPath {
  OVERVIEW = "/provider/overview",
  SCHEDULE = "/provider/schedule",
  ANALYTICS = "/provider/analytics",
  PATIENT = "/provider/clients",
  PROVIDER = "/provider/providers",
  SESSION = "/provider/session",
  GROUP_MEETING = "/provider/meeting",
  REPORTS = "/provider/reports",
  MEETINGS = "/provider/meetings",
  MEETINGSFORM = "/provider/meetings/create-meeting",
  PERMISSIONS = "/provider/permissions",
  SETTINGS = "/provider/settings",
  SESSION_DETAILS_REPORTS = "/provider/reports/session-details",
  IN_PERSON_SESSION_DETAILS_REPORTS = "/provider/reports/in-person-session-details",
  PAYMENTS_COLLECTED = "/provider/reports/payments-collected",
  REVENUE_BY_PROVIDER = "/provider/reports/revenue-by-provider",
  APPOINTMENTS_BY_PROVIDER = "/provider/reports/appointments-by-provider",
  UPDATE_PERMISSION = "/provider/permissions/update",
  ROOT = "/provider",
  LOGIN = "/",
}
