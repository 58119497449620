import React, { useState } from "react";
import { validate } from "email-validator";
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { Alert } from "antd";
import {
  authenticate,
  firebaseAuth,
  registerUserFunction,
} from "../../store/firebase";
import sideImage1 from "../../assets/images/sideImage1.png";
import { InputComponent } from "../elements/InputComponent";
import { TextInput } from "../elements/TextInput";
import { Loader } from "../elements/Loader";
import { PrimaryButtonComponent } from "../elements/PrimaryButtonComponent";
import {
  StyledAlert,
  StyledCenteredDiv,
  StyledDivider,
  StyledEventButton,
} from "../elements/UIElements";
import { Validation } from "../elements/Validation";
import { OnBoardingTopText } from "../elements/OnBoardingTopText";
import { OnBoardingScaffold } from "../layout/OnBoardingScaffold";

declare const document: any;

export const RegisterScreen = () => {
  const minPasswordLength = 8;
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailValidation, showEmailValidation] = useState(false);
  const [passwordValidation, showPasswordValidation] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [loader, showLoader] = useState(false);

  const alertClose = () => {
    setAlertVisible(false);
  };

  const clearValidation = () => {
    showEmailValidation(false);
    showPasswordValidation(false);
    showLoader(true);
  };

  const proceedWithRegistration = (signInEmail: string) => {
    // get the permission for user
    const registerUser = registerUserFunction();
    registerUser({ email: signInEmail }).then((response: any) => {
      const permission = response.data;
      if (permission && !permission.locked) {
        history.push(`/provider/overview`, {
          permission,
        });
      } else {
        setAlertVisible(true);
        showLoader(false);
      }
    });
  };

  const signUpWithEmail = () => {
    setAlertVisible(false);
    showLoader(true);
    firebaseAuth
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential: any) => {
        proceedWithRegistration(userCredential.user.email);
      })
      .catch(() => {
        setAlertVisible(true);
        showLoader(false);
      });
  };

  const signUpWithGoogle = () => {
    setAlertVisible(false);
    showLoader(true);
    authenticate()
      .then((userCredential: any) => {
        proceedWithRegistration(userCredential.user.email);
      })
      .catch(() => {
        setAlertVisible(true);
        showLoader(false);
      });
  };

  const signUpWithApple = () => {
    // do apple sign up
  };
  const handleClick = async () => {
    clearValidation();

    if (!email || !validate(email.trim())) {
      showEmailValidation(true);
      showLoader(false);
      return;
    }

    if (!password || password.length < minPasswordLength) {
      showPasswordValidation(true);
      showLoader(false);
      return;
    }
    signUpWithEmail();
  };

  return (
    <OnBoardingScaffold
      topItem={
        <OnBoardingTopText
          prefixText="Explore Medcon - Secure Telemedicine"
          logo=""
          segment=""
          botMessage="We have made it easy to collect your copays and meet your patients online."
        />
      }
      sideImage={sideImage1}
    >
      {alertVisible ? (
        <StyledAlert>
          <Alert
            message="We are unable to sign you up, please try again"
            type="error"
            showIcon
            closable
            onClose={alertClose}
          />
        </StyledAlert>
      ) : (
        ""
      )}
      <form
        onSubmit={async (e: any) => {
          e.preventDefault();
        }}
      >
        <InputComponent
          input={
            <TextInput
              onChange={(e: any) => {
                setEmail(e.target.value);
              }}
              type="text"
              placeholder="Email"
              onKeyUp={(e: any) => {
                if (e.keyCode === 13) {
                  document.getElementById("logInPassword").focus();
                }
              }}
            />
          }
          validation={
            <Validation
              text="Please enter valid email address"
              visible={emailValidation}
            />
          }
        />
        <InputComponent
          input={
            <TextInput
              type="password"
              placeholder="Password"
              onChange={(e: any) => {
                setPassword(e.target.value);
              }}
              maxLength={50}
              onKeyPress={(e: any) => {
                if (e && e.length < minPasswordLength) {
                  e.preventDefault();
                  return false;
                }
                return true;
              }}
              onKeyUp={(e: any) => {
                if (e.keyCode === 13) {
                  handleClick();
                }
              }}
              id="logInPassword"
            />
          }
          validation={
            <Validation
              text="Password must be at least eight(8) or more characters"
              visible={passwordValidation}
            />
          }
        />
        <PrimaryButtonComponent
          onClick={handleClick}
          buttonText="Get Started"
        />
      </form>
      <StyledDivider>or</StyledDivider>

      <StyledEventButton
        width={50}
        onClick={() => {
          signUpWithGoogle();
        }}
      >
        <FcGoogle size={15} />
        <p>Sign up with Google</p>
      </StyledEventButton>
      <br />

      <StyledEventButton
        width={50}
        onClick={() => {
          signUpWithApple();
        }}
      >
        <FaApple size={15} />
        <p>Sign up with Apple</p>
      </StyledEventButton>

      <StyledCenteredDiv>
        <br />
        <br />
        By signing up, you indicate that you have read and agreed to the{" "}
        <a href="">Terms of Service </a>
        <br />
        <br />
        <p>
          Already have an account?
          <a href="/"> Sign in now.</a>
        </p>
      </StyledCenteredDiv>

      <Loader visible={loader} text="Please wait ... " />
    </OnBoardingScaffold>
  );
};
