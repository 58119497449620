import React from "react";
import {
  StyledBlackTd,
  StyledfadeTd,
  StyledFancyTd,
  StyledSpecialFontTd,
} from "../styles/tableRowStyle";

export const AnalyticTableRow = (props: {
  data: {
    sessionTitle: string;
    location: string;
    patient: string;
    status: string;
    timeStamp: string;
    amountPaid: string;
  };
}) => (
  <tr>
    <StyledBlackTd>{props.data.sessionTitle}</StyledBlackTd>
    <StyledfadeTd>{props.data.location}</StyledfadeTd>
    <td>{props.data.patient}</td>
    <StyledfadeTd>{props.data.status}</StyledfadeTd>
    <StyledSpecialFontTd className="specialFont">
      {props.data.timeStamp}
    </StyledSpecialFontTd>
    <td>
      {" "}
      <StyledFancyTd>{props.data.amountPaid}</StyledFancyTd>
    </td>
  </tr>
);
