import React, { useEffect, useContext, useState } from "react";
import { Col, Input, Select, Form, Alert } from "antd";
import styled from "styled-components";
import { Loader } from "../elements/Loader";
import { device } from "../../assets/breakbpoints";
import { fontFamily } from "../../assets/fontFamily";
import SettingStore from "../../store/setting";
import { TimeZoneLabel, CurrencyLabel, DateFormat } from "../../types/enum";
import { useScreenNameContext } from "../context/screenNameContext";
import { ApptButtonComponent } from "../elements/PrimaryButtonComponent";
import { PermissionContext } from "../context/permissionContext";
import { Setting } from "../../types/setting";
import countryList from "../../types/countryList";

const StyledCreateMeetingForm = styled.div`
  background-color: #fff;
  border-radius: 16px;
  padding: 51px;
  margin-top: 30px;

  & h1 {
    color: #131523;
    font-size: 16px;
    font-family: ${fontFamily.Inter};
    font-weight: 700;
  }

  & > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  @media ${device.laptop} {
    & h1 {
      font-size: 1.2vw;
    }
  }
`;

export const Settings = () => {
  const { permission } = useContext(PermissionContext) as any;
  const { setCurrentPageName } = useScreenNameContext() as any;
  const [loader, showLoader] = useState(true);
  const [alertVisible, setAlertVisible] = useState(false);
  const [timeZone, setTimeZone] = useState("");
  const [dateFormat, setDateFormat] = useState("");
  const [defaultCurrency, setDefaultCurrency] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [businessPhone, setBusinessPhone] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [appointmentSheet, setAppointmentSheet] = useState("");
  const [reportGoogleFolder, setReportGoogleFolder] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    setCurrentPageName("Settings");
    if (permission) {
      const settingStore = new SettingStore();
      settingStore.getBusinessSettings().then((setting: Setting) => {
        setCompanyName(setting.companyName);
        setTimeZone(setting.timeZone);
        setDateFormat(setting.dateFormat);
        setDefaultCurrency(setting.defaultCurrency);
        setStreetAddress(setting.streetAddress!);
        setCity(setting.city!);
        setState(setting.state!);
        setCountry(setting.country!);
        setPostalCode(setting.postalCode!);
        setBusinessPhone(setting.businessPhone!);
        setEmail(setting.email!);
        setWebsite(setting.website!);
        setAppointmentSheet(setting.appointmentSheet!);
        setReportGoogleFolder(setting.reportGoogleFolder!);
        setLogoUrl(setting.logoUrl!);
        showLoader(false);
      });
    }
  }, [permission]);

  function SaveSetting() {
    if (companyName && defaultCurrency && dateFormat && timeZone) {
      showLoader(true);
      const settingStore = new SettingStore();
      const setting = {
        companyName,
        timeZone,
        dateFormat,
        defaultCurrency,
        streetAddress,
        city,
        state,
        country,
        businessPhone,
        email,
        website,
        appointmentSheet,
        reportGoogleFolder,
        logoUrl,
        postalCode,
      } as Setting;
      settingStore.saveSetting(setting).then((response: boolean) => {
        setAlertMessage("Company settings successfully updated.");
        showLoader(false);
        setAlertVisible(response);
      });
    }
  }

  return (
    <>
      <Col xs={{ span: 24 }} lg={{ span: 20 }}>
        {alertVisible ? (
          <>
            <br />
            <Alert
              message={alertMessage}
              type="success"
              showIcon
              closable
              onClose={() => {
                setAlertVisible(false);
              }}
            />
          </>
        ) : (
          ""
        )}

        <StyledCreateMeetingForm>
          <h1>Business Information</h1>
          <br />

          <Form labelCol={{ span: 9 }} wrapperCol={{ span: 15 }}>
            <Form.Item
              label="Company Name"
              style={{ float: "left", width: "50%" }}
            >
              <Input
                type="text"
                value={companyName}
                onChange={(e: any) => setCompanyName(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Email Address"
              style={{ float: "left", width: "50%" }}
            >
              <Input
                type="text"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Business Phone Number"
              style={{ float: "left", width: "50%" }}
            >
              <Input
                type="text"
                value={businessPhone}
                onChange={(e: any) => setBusinessPhone(e.target.value)}
              />
            </Form.Item>

            <Form.Item label="Website" style={{ float: "left", width: "50%" }}>
              <Input
                type="text"
                value={website}
                onChange={(e: any) => setWebsite(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Street Address"
              style={{ float: "left", width: "50%" }}
            >
              <Input
                type="text"
                value={streetAddress}
                onChange={(e: any) => setStreetAddress(e.target.value)}
              />
            </Form.Item>

            <Form.Item label="City" style={{ float: "left", width: "50%" }}>
              <Input
                type="text"
                value={city}
                onChange={(e: any) => setCity(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="State/Province"
              style={{ float: "left", width: "50%" }}
            >
              <Input
                type="text"
                value={state}
                onChange={(e: any) => setState(e.target.value)}
              />
            </Form.Item>

            <Form.Item label="Country" style={{ float: "right", width: "50%" }}>
              <Select value={country} onChange={(e: any) => setCountry(e)}>
                <Select.Option value="">Select Country</Select.Option>
                {countryList.map((entry: any) => (
                  <Select.Option key={entry.code} value={entry.name}>
                    {entry.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Zip/Postal Code"
              style={{ float: "left", width: "50%" }}
            >
              <Input
                type="text"
                value={postalCode}
                onChange={(e: any) => setPostalCode(e.target.value)}
              />
            </Form.Item>

            <div style={{ clear: "both" }} />

            <h1>Business Setup</h1>
            <br />

            <Form.Item
              label="Timezone"
              style={{ float: "right", width: "50%" }}
            >
              <Select value={timeZone} onChange={(e: any) => setTimeZone(e)}>
                {Object.values(TimeZoneLabel).map((value) => (
                  <Select.Option value={value}>{value}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Date Format"
              style={{ float: "right", width: "50%" }}
            >
              <Select
                value={dateFormat}
                onChange={(e: any) => setDateFormat(e)}
              >
                {Object.values(DateFormat).map((value) => (
                  <Select.Option value={value}>{value}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Default Currency"
              style={{ float: "right", width: "50%" }}
            >
              <Select
                value={defaultCurrency}
                onChange={(e: any) => setDefaultCurrency(e)}
              >
                {Object.values(CurrencyLabel).map((value) => (
                  <Select.Option value={value}>{value}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Appointment Google Sheet Url"
              style={{ float: "left", width: "50%" }}
            >
              <Input
                type="text"
                value={appointmentSheet}
                onChange={(e: any) => setAppointmentSheet(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Reports Google Drive Folder Url"
              style={{ float: "left", width: "50%" }}
            >
              <Input
                type="text"
                value={reportGoogleFolder}
                onChange={(e: any) => setReportGoogleFolder(e.target.value)}
              />
            </Form.Item>

            <Form.Item label="Logo Url" style={{ float: "left", width: "50%" }}>
              <Input
                type="text"
                value={logoUrl}
                onChange={(e: any) => setLogoUrl(e.target.value)}
              />
            </Form.Item>
          </Form>
          <div style={{ textAlign: "center" }}>
            <ApptButtonComponent buttonText="Update" onClick={SaveSetting} />
          </div>
        </StyledCreateMeetingForm>
      </Col>
      <Loader visible={loader} text="..." />
    </>
  );
};
